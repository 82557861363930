<template>
	<div class='wrapper'>
		<!-- Basic protection ------------------------------------------------------------------------------------- -->
		<MyTitle Title="Basic protection" />
		<!-- 表格:不展示表头，共两列 名称、多选框 -->
		<el-table :data="BasicTableData" style="width: 100%;padding: 20px;" :show-header="false">
			<el-table-column prop="label" width="700">
			</el-table-column>
			<el-table-column prop="Recording" label="Video Recording">
				<template slot-scope="scope">
					<ElementRadiogroup :status.sync="scope.row.recording"></ElementRadiogroup>
				</template>
			</el-table-column>
		</el-table>

		<!-- Other Protection 引入添加组件-------------------------------------------------------------------------- -->
		<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex">
			Other Protection
			<i class="el-icon-plus addIcon" @click="otherProtectionAddRow"></i>
		</Navbar>
		<el-table :data="otherProtectionData" style="width: 100%;padding: 20px;" :show-header="false">
			<el-table-column prop="field_key" width="700">
				<template slot-scope="scope">
					<el-input v-model="scope.row.field_key" placeholder="Name" @input="scope.row.field_key = $util.inputReg(scope.row.field_key)"></el-input>
				</template>
			</el-table-column>
			<el-table-column prop="field_value" label="">
				<template slot-scope="scope">
					<ElementRadiogroup :status.sync="scope.row.field_value"></ElementRadiogroup>
				</template>
			</el-table-column>
			<el-table-column width="100px">
				<template slot-scope="scope">
					<i class="el-icon-delete" @click="otherProtectionDeleteRow(scope.row.dynamic_field_order)"></i>
				</template>
			</el-table-column>
		</el-table>

		<!-- Savings and investment ------------------------------------------------------------------------------- -->
		<MyTitle Title="Savings and investment" />
		<p style="color: #6E6E6E;margin-top: 30px;line-height: 24px;">Saving for education fund</p>
		<el-table :data="protectionTableData" style="width: 100%;padding: 20px;" :show-header="false">
			<el-table-column prop="dependant_name" width="700">
			</el-table-column>
			<el-table-column prop="Recording" label="">
				<template slot-scope="scope">
					<ElementRadiogroup :status.sync="scope.row.dependant_financial_objectives_value"></ElementRadiogroup>
				</template>
			</el-table-column>
		</el-table>

		<!-- Other Wealth Accumulation & Preservation Goals 引入添加组件-------------------------------------------- -->
		<Navbar backgroundColor="#e8e8e8" fontSize="18px" className="flex">
			Other Wealth Accumulation & Preservation Goals
			<i class="el-icon-plus addIcon" @click="otherWealthAddRow"></i>
		</Navbar>
		<el-table :data="otherWealthData" style="width: 100%;padding: 20px;" :show-header="false">
			<el-table-column prop="field_key" width="700">
				<template slot-scope="scope">
					<el-input v-model="scope.row.field_key" placeholder="Name" @input="scope.row.field_key = $util.inputReg(scope.row.field_key)"></el-input>
				</template>
			</el-table-column>
			<el-table-column prop="field_value" label="">
				<template slot-scope="scope">
					<ElementRadiogroup :status.sync="scope.row.field_value"></ElementRadiogroup>
				</template>
			</el-table-column>
			<el-table-column width="100px">
				<template slot-scope="scope">
					<i class="el-icon-delete" @click="otherWealthDeleteRow(scope.row.dynamic_field_order)"></i>
				</template>
			</el-table-column>
		</el-table>

		<!-- Assumptions-------------------------------------------------------------- -------------------------- - -->
		<MyTitle Title="Assumptions" />
		<el-form :model="financial_objectives" label-position="left" class="mt20px my-form">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Annual Rate of Inflation(%)">
						<el-input v-model="financial_objectives.annual_growth_rate" @input="financial_objectives.annual_growth_rate = $util.inputReg(financial_objectives.annual_growth_rate)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Annual Rate of return(ROR)(%)">
						<el-input v-model="financial_objectives.ror" @input="financial_objectives.ror = $util.inputReg(financial_objectives.ror)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import ElementRadiogroup from "../../../components/ElementRadiogroup.vue";
import MyTitle from "../components/MyTitle.vue";
import Navbar from "../../../components/Navbar.vue";
import { setAllKYC } from "@/utils/kyc"

export default {
	components: { ElementRadiogroup, MyTitle, Navbar },
	data () {
		return {
			financial_objectives: {
				additional_notice: '',
				// Basic protection
				income_protection_death: '',
				income_protection_disability: '',
				critical_illness: '',
				medical_hospitalisation_costs: '',
				premium_waiver: '',
				outpatient_medical_expenses_protection: '',
				dental_expenses_protection: '',
				personal_accident: '',
				long_term_care: '',
				dependant_protection_planning: '',
				// Savings and investment
				myself_education_fund: '',
				retirement_needs: '',
				// type  other protection---protection   wealth---savings
				other_financial_objectives: [],
				// Assumptions
				ror: "5", //回报率
				annual_growth_rate: "3", //年增长率
				dependants_financial_objectives: [], // 从1.3节点获取信息
			},
			otherProtectionData: [],
			protectionTableData: [],
			otherWealthData: [],
			currentItem: null,
			// 存储初始状态的Assumptions，若发生变化则更新1.12数据；否则，不更新1.12数据
			initData: {
				ror: '',
				annual_growth_rate: ''
			},
			timer:null
		}
	},
	computed: {
		BasicTableData () {
			return [
				{
					value: 'income_protection_death',
					label: 'Death',
					recording: this.financial_objectives.income_protection_death
				}, {
					value: 'income_protection_disability',
					label: 'Total permanent disability',
					recording: this.financial_objectives.income_protection_disability
				}, {
					value: 'critical_illness',
					label: 'Critical Illness',
					recording: this.financial_objectives.critical_illness
				}, {
					value: 'medical_hospitalisation_costs',
					label: 'Medical and hospitalisation costs',
					recording: this.financial_objectives.medical_hospitalisation_costs
				}, {
					value: 'premium_waiver',
					label: 'Premium Waiver',
					recording: this.financial_objectives.premium_waiver
				}, {
					value: 'outpatient_medical_expenses_protection',
					label: 'Outpatient medical expenses protection',
					recording: this.financial_objectives.outpatient_medical_expenses_protection
				}, {
					value: 'dental_expenses_protection',
					label: 'Dental expenses protection',
					recording: this.financial_objectives.dental_expenses_protection
				}, {
					value: 'personal_accident',
					label: 'Personal accident',
					recording: this.financial_objectives.personal_accident
				}, {
					value: 'long_term_care',
					label: 'Long-term care',
					recording: this.financial_objectives.long_term_care
				}, {
					value: 'dependant_protection_planning',
					label: "Planning for dependant's protection",
					recording: this.financial_objectives.dependant_protection_planning
				}
			]
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// 判断rate是否有变化
			let changeRate = this.initData.ror !== this.financial_objectives.ror || this.initData.annual_growth_rate !== this.financial_objectives.annual_growth_rate
			setAllKYC(this.$store, this.currentItem, changeRate)
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			if (this.timer) clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
					this.getDataFromAll(newVal)
				})
			}, 200)
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest')
		},
		setKYC (val) {
			this.BasicTableData.map(res => {
				this.financial_objectives[res.value] = res.recording
			})
			let tempData = []
			this.protectionTableData.map(res => {
				if (res.value === 'myself_education_fund' || res.value === 'retirement_needs') {
					this.financial_objectives[res.value] = res.dependant_financial_objectives_value
				}
				if (res.dependant_id) {
					tempData.push(res)
				}
			})
			this.financial_objectives.dependants_financial_objectives = tempData
			// other_financial_objectives赋值
			this.financial_objectives.other_financial_objectives = []
			if (this.otherProtectionData.length > 0) { this.financial_objectives.other_financial_objectives = this.financial_objectives.other_financial_objectives.concat(this.otherProtectionData) }
			if (this.otherWealthData.length > 0) { this.financial_objectives.other_financial_objectives = this.financial_objectives.other_financial_objectives.concat(this.otherWealthData) }
			// kycAllData赋值
			Object.keys(this.kycAllData.kyc.client[val].financial_objectives).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].financial_objectives[key] = this.financial_objectives[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// Other Protection 添加
		otherProtectionAddRow () {
			this.otherProtectionData.push({
				dynamic_field_order: Date.now().toString(),
				dynamic_field_type: 'protection',
				field_key: '',
				field_value: ''
			})
		},
		// Other Protection 删除
		otherProtectionDeleteRow (order) {
			this.otherProtectionData = this.otherProtectionData.filter(n => n.dynamic_field_order !== order)
		},
		// ther Wealth Accumulation & Preservation Goals 添加
		otherWealthAddRow () {
			this.otherWealthData.push({
				dynamic_field_order: Date.now().toString(),
				dynamic_field_type: 'savings',
				field_key: '',
				field_value: ''
			})
		},
		// ther Wealth Accumulation & Preservation Goals 删除
		otherWealthDeleteRow (order) {
			this.otherWealthData = this.otherWealthData.filter(n => n.dynamic_field_order !== order)
		},
		getDataFromAll (val) {
			let dataValue = this.kycAllData.kyc.client[val]
			// financial_objectives赋值
			Object.keys(this.financial_objectives).map(key => {
				this.financial_objectives[key] = dataValue.financial_objectives[key]
			})
			this.initData.ror = dataValue.financial_objectives.ror
			this.initData.annual_growth_rate = dataValue.financial_objectives.annual_growth_rate
			// 从1.3节点获取，并格式话数据-----------------------------------------------------------------------
			this.protectionTableData = [{
				value: 'myself_education_fund',
				dependant_name: 'Myself',
				dependant_financial_objectives_value: dataValue.financial_objectives.myself_education_fund
			}]
			this.protectionTableData = this.protectionTableData.concat(dataValue.financial_objectives.dependants_financial_objectives)
			this.protectionTableData.push(
				{
					value: 'retirement_needs',
					dependant_name: 'Saving for retirement needs',
					dependant_financial_objectives_value: dataValue.financial_objectives.retirement_needs
				}
			)
			// other赋值
			this.financial_objectives.other_financial_objectives.map(res => {
				if (res.dynamic_field_type === 'savings') {
					this.otherWealthData.push(res)
				} else {
					this.otherProtectionData.push(res)
				}
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.currentItem = this.$store.state.KYC.kycPageItem
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	destroyed () { // this.setKYC(this.kycClient)
		let changeRate = this.initData.ror !== this.financial_objectives.ror || this.initData.annual_growth_rate !== this.financial_objectives.annual_growth_rate
		setAllKYC(this.$store, this.currentItem, changeRate)
	}
}
</script>
<style lang='css' scoped>
.addIcon {
	font-size: 24px;
	font-weight: bold;
	line-height: 50px;
	cursor: pointer;
}
.el-table >>> .el-input__inner {
	padding-left: 0;
}
/* 当table无数据时，不显示暂无数据 */
.el-table >>> .el-table__empty-block {
	min-height: 0;
}
.el-table >>> .el-table__empty-text {
	height: 0;
}
</style>