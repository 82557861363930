<template>
	<div class='wrapper'>
		<el-dialog class="dialog" :close-on-click-modal="false" destroy-on-close ref="dialog" :visible.sync="visible" :fullscreen="true" :show-close="false">
			<slot slot="title">
				<div class="displayFlex flexAlignCenter w50 h50px" :style="ifSecondClient ? {borderBottom: '6px solid #8FBDFE'} : {borderBottom: '6px solid #f3a246'}">
					<img :src="imgs[dataValue.personal_information.gender]" alt="" width="40px">
					<span class="ml20px">{{Incompletedata[0].clientName}}</span>
				</div>
				<div class="displayFlex flexAlignCenter w50 h50px" v-if="ifSecondClient" style="border-bottom: 6px solid #8FBDFE;">
					<img :src="imgs[dataValue.personal_information.gender]" alt="" width="40px">
					<span class="ml20px">{{Incompletedata[1].clientName}}</span>
				</div>
			</slot>
			<!-- 左右布局 -->
			<div class="displayFlex h100">
				<!-- client0 -->
				<div class="w50 boxSizing pr50px">
					<div v-for="(temp, i) in Incompletedata[0].NotCompletedData" :key="i">
						<h1 @click="jumpToPage(temp.pageItem)" v-if="temp.data.length > 0">{{temp.node}}</h1>
						<p v-for="(d, i) in temp.data" :key="i">
							{{d}}
							<el-button type="danger" plain>Required</el-button>
						</p>
					</div>
				</div>
				<!-- client0 -->
				<div class="w50 boxSizing pr50px" v-if="ifSecondClient">
					<div v-for="(temp, i) in Incompletedata[1].NotCompletedData" :key="i">
						<h1 @click="jumpToPage(temp.pageItem)">{{temp.node}}</h1>
						<p v-for="(d, i) in temp.data" :key="i">
							{{d}}
							<el-button type="danger" plain>Required</el-button>
						</p>
					</div>
				</div>
			</div>
			<span slot="footer">
				<div class="TextAlginCenter pointer" @click="Close">Close</div>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import ElementDialog from '@/components/ElementDialog/index.vue';
import { WhetherCompleted } from "@/utils/kyc"

export default {
	components: { ElementDialog },
	data () {
		return {
			// Dialog弹窗
			visible: true,
			imgs: {
				Male: require('@/assets/img/avatar/avatar.mr.lg.png'),
				Female: require('@/assets/img/avatar/avatar.mrs.lg.png'),
			},
			// 未填写完成数组 则数据长度与client个数相同 为1或2
			Incompletedata:
				[
					{
						clientName: '',
						NotCompletedData: []
					}, {
						clientName: '',
						NotCompletedData: []
					}
				],
			// 是否存在两个client
			ifSecondClient: false,
			kycAllData: {},
			dataValue: {}
		};
	},
	computed: {},
	watch: {},
	methods: {
		Close () {
			this.visible = false
			this.$parent.showSignSubmit = false
		},
		jumpToPage (val) {
			this.Close()
			// setTimeout(() => {
			this.$emit('changePageByClick', val)
			// }, 1000);
			// this.$store.commit('SET_KYCPAGEITEM', val)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.dataValue = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient]
		if (this.$store.state.User.clientNameArr) {
			this.$store.state.User.clientNameArr.map((n, i) => {
				this.Incompletedata[i].clientName = n
			})
		}
		if (this.Incompletedata[1].clientName) this.ifSecondClient = true
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.Incompletedata[0].NotCompletedData = WhetherCompleted(this.$store)
		console.log(this.Incompletedata);
	},
}
</script>
<style lang='scss' scoped>
span {
	font-size: 20px;
	color: #707070;
	line-height: 28px;
	letter-spacing: 0px;
}
h1 {
	font-size: 22px;
	color: #302f2f;
	text-decoration: underline;
	margin: 20px 0;
	cursor: pointer;
}
p {
	font-size: 20px;
	color: #707070;
	line-height: 28px;
	margin-left: 20px;
	margin-top: 10px;
	border-bottom: 1px solid;
	padding-bottom: 10px;
}
/deep/ .el-dialog {
	display: flex;
	flex-direction: column;
	.el-dialog__body {
		flex: 1;
		padding: 20px;
	}
	.el-dialog__header {
		padding: 20px 20px 0px;
		display: flex;
	}
}
</style>