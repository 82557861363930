<template>
	<div class='wrapper'>
		<!-- Protection goals------------------------------------------------------------------------ -->
		<MyTitle Title="Protection goals" class="mt20px mb20px" />
		<!-- 表格 -->
		<el-table :data="goalsTableData.filter((v) => v.fin_key === undefined || (dataValue.financial_objectives[v.fin_key] !== '' && dataValue.financial_objectives[v.fin_key] != 'NA'))" style="width: 100%" :show-header="false">
			<el-table-column prop="type" label="Protection goals">
			</el-table-column>
			<el-table-column prop="Amount" label="Annual Amount" width="200px">
				<template slot-scope="scope">
					<span v-if="scope.row.text && scope.row.text === 'text'">{{scope.row.Amount}}</span>
					<span v-else>S$ {{$formatMoney(scope.row.AmountShow)}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="" label="Lump Sum" width="100px">
				<template slot-scope="scope">
					<i class="el-icon-right pointer iStyle" @click="jumpToPage(scope.row.path)"></i>
				</template>
			</el-table-column>
		</el-table>
		<!-- Savings & Accumulation goals------------------------------------------------------------------------ -->
		<MyTitle Title="Savings & Accumulation goals" class="mt20px mb20px" />
		<!-- 表格 -->
		<el-table :data="SavingsGoalsTableData.filter(v => v.ifShow)" style="width: 100%" :show-header="false">
			<el-table-column prop="type" label="Protection goals">
			</el-table-column>
			<el-table-column prop="Amount" label="Annual Amount" width="200px">
				<template slot-scope="scope">
					S${{$formatMoney(scope.row.AmountShow)}}
				</template>
			</el-table-column>
			<el-table-column prop="" label="Lump Sum" width="100px">
				<template slot-scope="scope">
					<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
				</template>
			</el-table-column>
		</el-table>
		<div class="mt20px ml60" style="color: #606266;">Total <span style="color: black;">S$ {{$formatMoney(total_savings_accumulation_goals_show)}}</span></div>
	</div>
</template>

<script>
import { Message } from 'element-ui';
import MyTitle from "../components/MyTitle.vue";
import { setAllKYC } from "@/utils/kyc"

export default {
	components: { MyTitle },
	data () {
		return {
			goalsTableData: [
				{
					type: 'Death',
					Amount: '',
					path: '/IncomeProtectionUponDeath',
					key: 'total_death',
					fin_key: 'income_protection_death'
				}, {
					type: 'Total permanent disability',
					Amount: '',
					path: '/TotalPermanentDisability',
					key: 'total_disability',
					fin_key: 'income_protection_disability'
				}, {
					type: 'Critical Illness',
					Amount: '',
					path: '/CriticalIllness',
					key: 'total_ilness',
					fin_key: 'critical_illness'
				}, {
					type: 'Medical and hospitalisation costs',
					Amount: '',
					path: '/MedicalAndHospitalisationCosts',
					text: 'text',
					fin_key: 'medical_hospitalisation_costs'
				}, {
					type: 'Outpatient medical expenses protection',
					Amount: '',
					path: '/OutpatientMedicalExpensesProtection',
					key: 'plan_total_amount_outpatient',
					fin_key: 'outpatient_medical_expenses_protection'
				}, {
					type: 'Dental expenses protection',
					Amount: '',
					path: '/DentalExpensesProtection',
					key: 'total_amount_plan_dental',
					fin_key: 'dental_expenses_protection'
				}, {
					type: 'Personal accident',
					Amount: '',
					path: '/PersonalAccident',
					key: 'total_accident',
					fin_key: 'personal_accident'
				}, {
					type: 'Long term care protection',
					Amount: '',
					path: '/LongTermCareProtection',
					key: 'total_amount_ltc',
					fin_key: 'long_term_care'
				}, {
					type: "Planning for dependant's protection",
					Amount: '',
					path: '/PlanningForDependantProtection',
					key: 'dependants_total_all',
					fin_key: 'dependant_protection_planning'
				}
			],
			SavingsGoalsTableData: [
				{
					type: 'Education fund',
					Amount: '',
					path: '/EducationList',
					key: 'total_education_fund',
					fin_key: 'myself_education_fund',
					ifShow: true
				}, {
					type: 'Retirement fund',
					Amount: '',
					path: '/RetirementFund',
					key: 'total',
					ifShow: true,
					fin_key: 'retirement_needs'
				}
			],
			needs_analysis: {
				additional_notice: '',
				// deate---------------------------------------------
				total_death: '',
				dependants_required_death: '',
				family_required_death: '',
				required_years_death: '',
				annual_growth_rate_death: '2',
				ror_death: '5',
				inflation_adjusted_return_rate_death: '',
				funds_required_death: '',
				add_total_liabilities: '',
				add_final_expenses: '',
				add_other_fund: '',
				total_required_death: '',
				less_existing_life_insurance: '',
				less_other_funds_available: '',
				// total Permanent
				total_disability: '',
				dependants_required_disability: '',
				sustain_ifestyle_required_disability: '',
				required_years_disability: '',
				annual_growth_rate_disability: '',
				ror_disability: '',
				inflation_adjusted_return_rate_disability: '',
				maintain_lifestyle_sum_disability: '',
				add_lump_sum_required: '',
				total_need_disability: '',
				less_existing_disability_benefit: '',
				other_funds_available_disability: '',
				// critical
				total_ilness: '',
				dependants_required_ilness: '',
				sustain_ifestyle_required_ilness: '',
				required_years_ilness: '',
				inflation_adjusted_return_rate_ilness: '',
				maintain_lifestyle_sum_ilness: '',
				add_medical_cost: '',
				add_other_funding_needs: '',
				estimated_amount_required_illness: '',
				less_existing_critical_illness_insurance: '',
				annual_growth_rate_ilness: '',
				ror_ilness: '',
				// medical
				dependants_required_medical: '',
				hospital_surgical_coverage: '',
				daily_room_board: '',
				surgical_expenses: '',
				hospital_income_required: '',
				ride: '',
				other_benefit: '',
				preferred_ward_type: '',
				preferred_hospital_type: '',
				// outpatient
				plan_total_amount_outpatient: '',
				want_amount_outpatient: '',
				less_existing_insurance_outpatient: '',
				// dental
				total_amount_plan_dental: '',
				want_amount_dental: '',
				less_existing_insurance_dental: '',
				// person
				total_accident: '',
				desired_personal_accident_benefit: '',
				less_current_resources_provisions: '',
				// longterm
				total_amount_ltc: '',
				monthly_amount_need_ltc: '',
				months_needed_number_ltc: '',
				less_existing_insurance_ltc: '',
				// planning---dependants_needs_list、dependants_total_all
				plan_dependant_protection: {},
				// protection others
				protection_goals_others: [], //financialObjectives.other_financial_objectives  dynamic_field_type:protection
				// education
				total_education_fund: '',
				education_fund: [], //dependants_list.dependants_details

				// retirement
				total: '',
				year_retirement: '',
				annual_income_required_pv: '',
				average_inflation_rate: '',
				fiannual_income_required_fveld: '',
				less_existing_regular_income: '',
				total_retirement_income_required: '',
				first_year_income: '',
				duration_of_retirement: '',
				adjusted_rate_return: '',
				total_amount_required: '',
				available_funds_meet_need: '',
				less_maturity_value: '',
				less_total_asset: '',
				annual_growth_rate_retirement: '2',
				ror_retirement: '5',
				// wealth others
				saving_accumulation_goals_others: [], //financialObjectives.other_financial_objectives  dynamic_field_type:savings
				// 总计
				total_savings_accumulation_goals: '',
				// 123
				retirement_income: '',
				total_protection_goals: '',
				less_other_funds_illness: '',
				total_medical_and_hospitalisation: '',
			},
			// 总计
			total_savings_accumulation_goals_show: 0,
			kycAllData: {},
			dataValue: {
				financial_objectives: {}
			},
			currentItem: null,
			timer:null
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setNeeds()
			if (this.timer) clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
					this.getDataFromAll(newVal)
				})
			}, 200)
		}
	},
	methods: {
		// 跳转页面
		jumpToPage (route) {
			if (route === '/PlanningForDependantProtection') {
				let dependants = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].dependants_list
				if (dependants.is_dependants === 'N' || dependants.dependants_details.length === 0) {
					return Message.error('No dependents')
				}
			}
			this.$router.push(route)
		},
		setKYC() {},
		submitForm () {
			this.$emit('nextBtnRequest')
		},
		// 设置2.2节点needs数据
		setNeeds () {
			// needs赋值 共五部分 1.common 2.other-protection 3.other-wealth 4.education 5.planning
			let arr = JSON.parse(JSON.stringify(this.goalsTableData))
			arr.push(this.SavingsGoalsTableData[1])
			setAllKYC(this.$store, this.currentItem, arr)
		},
		getDataFromAll (val) {
			// 获取最新的1.3/1.4节点内容
			setAllKYC(this.$store, 2)
			this.currentItem = this.$store.state.KYC.kycPageItem
			this.kycAllData = this.$store.state.KYC.kycAllData
			this.dataValue = this.kycAllData.kyc.client[val]
			// 若有值 则赋值
			Object.keys(this.needs_analysis).map(key => {
				this.needs_analysis[key] = this.dataValue.needs_analysis[key]
			})
			this.dataValue.financial_objectives.other_financial_objectives.map(res => {
				// other goalsTableData
				if (res.dynamic_field_type === 'protection' && res.field_value && res.field_value !== 'NA') {
					this.goalsTableData.push({
						id: res.dynamic_field_order,
						type: 'Other Protection: ' + res.field_key,
						Amount: '0.00',
						path: '/ProtectionGoalsOthers?id=' + res.dynamic_field_order
					})
					// other SavingsGoalsTableData
				} else if (res.dynamic_field_type === 'savings' && res.field_value && res.field_value !== 'NA') {
					this.SavingsGoalsTableData.push({
						id: res.dynamic_field_order,
						type: 'Other Wealth Accumulation & Preservation Goals: ' + res.field_key,
						Amount: '0.00',
						path: '/SavingAccumulationGoalsOthers?id=' + res.dynamic_field_order
					})
				}
			})
			this.goalsTableData.map(res => {
				if (res.key === 'dependants_total_all') {
					res.Amount = parseFloat(this.needs_analysis.plan_dependant_protection.dependants_total_all || 0)
					res.AmountShow = res.Amount.toFixed(2)
				} else if (res.key) {
					res.Amount = parseFloat(this.needs_analysis[res.key] || 0)
					res.AmountShow = res.Amount.toFixed(2)
				}
			})
			// other赋值
			this.needs_analysis.protection_goals_others.map(n => {
				this.goalsTableData.map(j => {
					if (j.id && n.index === j.id) {
						j.Amount = parseFloat(n.total || 0)
						j.AmountShow = parseFloat(n.total || 0).toFixed(2)
					}
				})
			})
			this.SavingsGoalsTableData.map(res => {
				if (res.key) {
					res.Amount = parseFloat(this.needs_analysis[res.key] || 0)
					res.AmountShow = res.Amount.toFixed(2)
					this.needs_analysis.total_savings_accumulation_goals += parseFloat(res.Amount)
					res.ifShow = res.fin_key === 'myself_education_fund' && this.needs_analysis.education_fund.length > 0 ||
						res.fin_key === 'retirement_needs' && this.dataValue.financial_objectives.retirement_needs !== '' && this.dataValue.financial_objectives.retirement_needs != 'NA'
				}
			})
			// other赋值
			this.needs_analysis.saving_accumulation_goals_others.map(n => {
				this.SavingsGoalsTableData.map(j => {
					if (j.id && n.index === j.id) {
						if (j.id && n.index === j.id) {
							j.Amount = parseFloat(n.total || 0)
							j.AmountShow = parseFloat(n.total || 0).toFixed(2)
							j.ifShow = true
						}
					}
				})
			})
			this.needs_analysis.total_savings_accumulation_goals = 0
			this.SavingsGoalsTableData.map(res => {
				this.needs_analysis.total_savings_accumulation_goals += parseFloat(res.Amount)
			})
			this.total_savings_accumulation_goals_show = this.needs_analysis.total_savings_accumulation_goals.toFixed(2)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
	},
	destroyed () {
		this.setNeeds()
	}
}
</script>
<style lang='css' scoped>
</style>