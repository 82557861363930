<template>
	<div class='wrapper'>
		<p>Getting your net asset position helps to facilitate the planning
			of your financial needs. Would you like to provide information on
			your assets and liabilities?</p>
		<SwitchBoxVue :status.sync="assets_liabilities.is_provide_cash_assets_liabilities" width="200px" />
		<!-- 选择yes时展示内容 -->
		<div v-show="assets_liabilities.is_provide_cash_assets_liabilities === 'Y'" class="yesBox displayFlex flexColumn">
			<!-- 表格 Dependant's Details -->
			<NavbarVue :backgroundColor="parseFloat(assets_liabilities.net_assets_position) < 0 ? '#FF4040' : 'green'" fontSize="24px" className="flexBox" color="#fff" height="80px" padding="5px 50px 5px 10px">
				<div class="displayFlex flexColumn">
					<span>Net assets position</span>
					<span style="fontSize: 16px;">total assets - total liabilities</span>
				</div>
				<span style="fontSize: 24px;margin-left: auto;">S$ {{$formatMoney(net_assets_position_show)}}</span>
			</NavbarVue>
			<!-- Assets-------------------------------------------------------------------------------------- -->
			<MyTitle Title="Assets" class="mt30px" />
			<el-table :data="AssetsTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Personal Assets">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S$ {{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop='' label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="displayFlex mt20px">
				<span class="w70 TextAlginEnd" style="color: #606266;">Total Assets</span>
				<span class="w30 ml20px">S$ {{$formatMoney(total_assets_show)}}</span>
			</div>
			<!-- Liabilities-------------------------------------------------------------------------------------- -->
			<MyTitle Title="Liabilities" class="mt30px" />
			<el-table :data="LiabilitiesTableData" style="width: 100%" :show-header="false">
				<el-table-column prop="title" label="Personal Assets">
				</el-table-column>
				<el-table-column prop="value" width="200px">
					<template slot-scope="scope">
						S$ {{$formatMoney(scope.row.value)}}
					</template>
				</el-table-column>
				<el-table-column prop='' label="Action" width="100px">
					<template slot-scope="scope">
						<i class="el-icon-right pointer iStyle" @click="$router.push(scope.row.path)"></i>
					</template>
				</el-table-column>
			</el-table>
			<div class="displayFlex mt20px mb20px">
				<span class="w70 TextAlginEnd" style="color: #606266;">Total Liabilities</span>
				<span class="w30 ml20px">S$ {{$formatMoney(total_liabilities_show)}}</span>
			</div>
			<!-- issue -->
			<p>Do you foresee any factors within the next 12-18 months which may significantly increase or decrease your net assets position?</p>
			<SwitchBoxVue :status.sync="assets_liabilities.is_assets_change_dramatically" width="200px" class="mb10px"/>
			<el-input type="textarea" v-model="assets_liabilities.assets_change_dramatically_detail" v-if="assets_liabilities.is_assets_change_dramatically === 'Y'" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" size="large" @input="assets_liabilities.assets_change_dramatically_detail = $util.inputReg(assets_liabilities.assets_change_dramatically_detail)">
			</el-input>
		</div>
		<!-- 选择no时展示内容 -->
		<div v-show="assets_liabilities.is_provide_cash_assets_liabilities === 'N'" class="yesBox">
			<el-input type="textarea" v-model="assets_liabilities.no_provide_reason" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" size="large" @input="assets_liabilities.no_provide_reason = $util.inputReg(assets_liabilities.no_provide_reason)">
			</el-input>
		</div>
	</div>
</template>

<script>

import SwitchBoxVue from "../../../components/switchBox.vue";
import MyTitle from "../components/MyTitle.vue";
import NavbarVue from "../../../components/Navbar.vue";

export default {
	components: { SwitchBoxVue, MyTitle, NavbarVue },
	data () {
		return {
			net_assets_position_show: '',
			total_assets_show: '',
			total_liabilities_show: '',
			assets_liabilities: {
				additional_notice: '', // 备注
				is_provide_cash_assets_liabilities: 'Y',
				no_provide_reason: '', // 不提供原因
				net_assets_position: '', // 总金额
				other_assets_liabilities: [], //自定义项集合
				// Assets----------------------------------------------
				total_assets: '',
				// 二级Personal Assets type: "person"------------------------------------
				total_personal_assets: '',
				personal_assets_savings: '',
				fixed_deposits: '',
				residential_property: '',
				motor_vehicles: '',
				// 二级Invested/CPF Assets  type: "property" type: "invested"--------------------------------
				total_invested_assets: '',
				shares: '',
				bond: '',
				unit_trust: '',
				ilp: '',
				insurance_cash_value: '',
				cpf_ordinary_account: '',
				cpf_specia_account: '',
				cpf_medisave_account: '',
				srs_account: '',
				retirement_account: '',
				business_interestabc: '',
				// Liabilities----------------------------------------
				total_liabilities: '',
				// 二级Short Term  type: "short"-----------------------------------------
				total_short_term_liabilities: '',
				credit_cards: '',
				overdrafts: '',
				outstanding_taxes: '',
				// 二级Long Term  type: "mortgage"  type: "long"-----------------------------------------
				total_long_term_liabilities: '',
				mortgage: '',
				car_loan: '',
				// 一年到一年半影响因素
				is_assets_change_dramatically: '',
				assets_change_dramatically_detail: '',
				// 不确定字段
				investment_property_mortage: '',
				cash_mortgage: '',
				field: '',
				no_net_asset_position_resaon: '',
			},
			kycAllData: {},
			timer:null
		};
	},
	computed: {
		AssetsTableData () {
			return [{
				title: 'Personal Assets',
				value: this.assets_liabilities.total_personal_assets,
				path: '/PersonalAssets'
			}, {
				title: 'Invested/CPF Assets',
				value: this.assets_liabilities.total_invested_assets,
				path: '/InvestedAssets'
			}]
		},
		LiabilitiesTableData () {
			return [{
				title: 'Short Term',
				value: this.assets_liabilities.total_short_term_liabilities,
				path: '/ShortTerm'
			}, {
				title: 'Long Term',
				value: this.assets_liabilities.total_long_term_liabilities,
				path: '/LongTerm'
			}]
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			if (this.timer) clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
					this.getDataFromAll(newVal)
				})
			}, 200)
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest')
		},
		setKYC (val) {
			this.assets_liabilities.other_assets_liabilities = []
			Object.keys(this.$store.state.KYC.Assets_liabilities).map(key => {
				this.assets_liabilities.other_assets_liabilities = this.assets_liabilities.other_assets_liabilities.concat(this.$store.state.KYC.Assets_liabilities[key])
			})
			Object.keys(this.kycAllData.kyc.client[val].assets_liabilities).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].assets_liabilities[key] = this.assets_liabilities[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		getDataFromAll (val) {
			Object.keys(this.assets_liabilities).map(key => {
				this.assets_liabilities[key] = this.kycAllData.kyc.client[val].assets_liabilities[key]
			})
			// assest total
			this.assets_liabilities.total_assets = (parseFloat(this.assets_liabilities.total_personal_assets) + parseFloat(this.assets_liabilities.total_invested_assets)).toFixed(10).replace(/(0+)$/g, '')
			// Liabilities total
			this.assets_liabilities.total_liabilities = (parseFloat(this.assets_liabilities.total_short_term_liabilities) + parseFloat(this.assets_liabilities.total_long_term_liabilities)).toFixed(10).replace(/(0+)$/g, '')
			// total=assest total-Liabilities total
			this.assets_liabilities.net_assets_position = (parseFloat(this.assets_liabilities.total_assets) - parseFloat(this.assets_liabilities.total_liabilities)).toFixed(10).replace(/(0+)$/g, '')
			// 用于展示 保留两位小数
			this.total_assets_show = (Math.round(this.assets_liabilities.total_assets * 100) / 100).toFixed(2)
			this.total_liabilities_show = (Math.round(this.assets_liabilities.total_liabilities * 100) / 100).toFixed(2)
			this.net_assets_position_show = (Math.round(this.assets_liabilities.net_assets_position * 100) / 100).toFixed(2)
			// 不需要进行赋值
			// this.$store.commit('SET_ASSETS_LIABILITIES', this.kycAllData.kyc.client[val].assets_liabilities.other_assets_liabilities)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
	destroyed () { }
}
</script>
<style lang='css' scoped>
p {
	color: #6c6c6c;
	font-size: 22px;
	margin-bottom: 20px;
}
.yesBox {
	padding: 30px 0;
}
span {
	line-height: 24px;
}
</style>