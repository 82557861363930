/*
 *@Author: 段彩翔
 *@Date: 2022-04-14 10:53:09
 *@Description: 
*/
<template>
	<div class='mt20px'>
		<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" :placeholder="placeholder" v-model="textarea" size="large" @input="textarea = $util.inputReg(textarea)">
		</el-input>
	</div>
</template>

<script>

export default {
	components: {},
	props: {
		placeholder: {
			type: String,
			default: 'Please state reason'
		},
		// 文本框值
		textareaValue: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			textarea: this.textareaValue
		};
	},
	computed: {},
	watch: {
		textarea (val) {
			this.$emit('update:textareaValue', val)
		}
	},
	methods: {

	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {

	},
}
</script>
<style lang='css' scoped>
</style>