<template>
	<div class='wrapper'>
		<!-- 未做风险评估问卷 -->
		<template v-if="!($store.state.KYC.Grade)">
			<!-- Important Notes to Client------------------------------------------------------- -->
			<MyTitle Title="Important Notes to Client"></MyTitle>
			<p>{{paragraph1}}</p>
			<p>{{paragraph2}}</p>
			<p>{{paragraph3}}</p>
			<p>{{paragraph4}}</p>
			<p>{{paragraph5}}</p>
			<!-- issue -->
			<div v-for="(item, index) in question_list" :key="index">
				<MyTitle :Title="item.question" class="mb20px mt30px" />
				<CheckBox checkBoxType="standardList" :content="item.answer_list" :question_order="item.question_order" @changeStatus="changeStatus" />
			</div>
		</template>
		<!-- 已做风险评估问卷 -->
		<template v-if="$store.state.KYC.Grade">
			<!-- 智能评估结果 -->
			<RiskGrade :ifCancheck="false" :checkedData.sync="risk_profile_questionnaire.grade" />
			<!-- 是否同意风险评估结果 -->
			<p class="descTitle">Do you agree with your risk profile?</p>
			<SwitchBox :status.sync="risk_profile_questionnaire.is_agree_profile" width="200px" />
			<template v-if="risk_profile_questionnaire.is_agree_profile === 'N'">
				<MyTitle Title="Client's Declaration (Own Choice)" class="mt20px"></MyTitle>
				<p class="descTitle"> I/We do not agree with the results of Risk Profile questionnaires and instruct the adviser to use my/our preferred risk profile as stated below.</p>
				<RiskGrade :checkedData.sync="risk_profile_questionnaire.own_choice" :showMore="false" />
			</template>
		</template>
		<el-button type="warning" size="medium" @click="reTakeHandler" style="position: fixed;bottom: 20px;" v-if="$store.state.KYC.Grade">Re-Take</el-button>
	</div>
</template>

<script>
import MyTitle from "../components/MyTitle.vue";
import CheckBox from "../components/checkBox.vue";
import RiskGrade from "../components/RiskGrade.vue";
import SwitchBox from "../../../components/switchBox.vue";
import { getQuestionAndAnswer, getGrade } from '@/api/KYC'
export default {
	components: { MyTitle, CheckBox, RiskGrade, SwitchBox },
	data () {
		return {
			question_list: [],
			paragraph1: "(1) The objective of completing the Risk Profile Questionnaire is to encourage investors to think of the risk (possible losses) that can "
				+ "accompany returns (possible profits). The scoring analysis of and figures quoted in the Risk Profile serve only as a general guide to an investor’s risk tolerance.",
			paragraph2: "(2) It is best practice guide by Financial Planning bodies to set aside an emergency fund of at least 6 months of living expenses "
				+ "before you accept our investment proposal.",
			paragraph3: "(3) In evaluating investment instrument such as unit trusts, past performance does not guarantee future performance.",
			paragraph4: "(4) Return and volatility data used in this Risk Questionnaire are meant as illustration rather than implying any guarantee. Actual "
				+ "data, particularly volatility, could be significantly higher as financial markets are subject to abrupt and extreme swings.",
			paragraph5: "(5) There may be loss of capital invested as a result of low market prices that occur during periods of extreme market conditions.",
			risk_profile_questionnaire: {
				additional_notice: '',
				risk_profile_questionnaire_answer: [], // 答案集合
				score: '0',
				level: '',
				grade: '',
				is_agree_profile: '',
				own_choice: '',
				// 不确定字段
				comment: '',
				no_take_reason: '',
				is_take_questions: '',
			},
			kycAllData: {},
			timer:null
		};
	},
	computed: {
		score () {
			let total = 0
			this.risk_profile_questionnaire.risk_profile_questionnaire_answer.map(res => {
				total += parseFloat(res.score)
			})
			return total
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			if (this.timer) clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
					this.getDataFromAll(newVal)
				})
			}, 200)
		}
	},
	methods: {
		async submit_Form () {
			// 判断是否全部作答完毕，如没有全部答完，则弹出提示
			if (this.risk_profile_questionnaire.risk_profile_questionnaire_answer.length !== this.question_list.length) {
				this.$confirm('Please complete the questionnaire', 'Alert', {
					cancelButtonText: 'OK',
					showConfirmButton: false
				}).catch(() => { })
				return
			}
			// 计算值score赋值
			this.risk_profile_questionnaire.score = this.score
			// 通过score获取评级
			let res = await getGrade({ score: String(this.score) })
			this.risk_profile_questionnaire.grade = res.content.grade
			this.risk_profile_questionnaire.level = global.gradeLevel.filter(n => n.name === res.content.grade)[0].code
			this.setKYC(this.kycClient)
			// 设置页面为结果页
			this.$store.commit('SET_GRADE', true)
		},
		async submitForm () {
			this.kycAllData.kyc.client[this.kycClient].risk_profile_questionnaire.is_agree_profile = this.risk_profile_questionnaire.is_agree_profile
			this.kycAllData.kyc.client[this.kycClient].risk_profile_questionnaire.own_choice = this.risk_profile_questionnaire.own_choice
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			this.$emit('nextBtnRequest', 9)
		},
		setKYC (val) {
			// 赋值
			Object.keys(this.kycAllData.kyc.client[val].risk_profile_questionnaire).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].risk_profile_questionnaire[key] = this.risk_profile_questionnaire[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		changeStatus (val, order) {
			let obj = {
				answer_id: val.answer_order,
				question_id: order,
				score: val.answer_score
			}
			// 如果已经存在则先删除 再push
			if (this.risk_profile_questionnaire.risk_profile_questionnaire_answer.some(n => n.question_id === obj.question_id)) {
				this.risk_profile_questionnaire.risk_profile_questionnaire_answer = this.risk_profile_questionnaire.risk_profile_questionnaire_answer.filter(n => n.question_id !== obj.question_id)
			}
			this.risk_profile_questionnaire.risk_profile_questionnaire_answer.push(obj)
		},
		// 重新评估
		reTakeHandler () {
			// 清空之前的答题
			this.risk_profile_questionnaire.risk_profile_questionnaire_answer = []
			// 返回答题页
			this.$store.commit('SET_GRADE', false)
		},
		getDataFromAll (val) {
			Object.keys(this.risk_profile_questionnaire).map(key => {
				this.risk_profile_questionnaire[key] = this.kycAllData.kyc.client[val].risk_profile_questionnaire[key]
			})
			this.$store.commit('SET_GRADE', this.risk_profile_questionnaire.grade !== '' ? true : false)
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		getQuestionAndAnswer({}).then(res => {
			this.question_list = res.content.question_list
		})
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 若有值 则赋值
		this.getDataFromAll(this.kycClient)

	},
	destroyed () { // this.setKYC(this.kycClient)
	},
}
</script>
<style lang='css' scoped>
p {
	margin-top: 30px;
	font-size: 18px;
	line-height: 24px;
	letter-spacing: 0px;
}
.descTitle {
	color: #6c6c6c;
	margin-bottom: 20px;
}
</style>