<template>
	<div class='wrapper'>
		<p>Would you like your dependant(s) to be taken into consideration for
			the Needs Analysis and Recommendation(s)?</p>
		<SwitchBoxVue :status.sync="dependants_list.is_dependants" width="200px" />
		<!-- 选择yes时展示内容 -->
		<div v-show="dependants_list.is_dependants === 'Y'" class="yesBox displayFlex flexColumn h80">
			<span>(e.g. spouse, children and elderly relatives)</span>
			<!-- 表格 Dependant's Details -->
			<MyTitle Title="Dependant's Details" class="mt30px" />
			<div style="height: 250px;">
				<ElementTable :configData="configData" :tableData="dependants_list.dependants_details" height="250">
					<el-table-column slot="operation" label="Action">
						<template slot-scope="scope">
							<i class="el-icon-edit" @click="editDetails(scope.row)"></i>
							<i class="el-icon-delete ml20px" @click="deleteDetails(scope.row)"></i>
						</template>
					</el-table-column>
				</ElementTable>
			</div>
			<!-- 添加按钮 -->
			<div class="circle">
				<i class="el-icon-plus" @click="AddDetails('Add')"></i>
			</div>
			<!-- Suggested -->
			<span>Suggested no. of years to support for a boy = no. of years till he
				turns 25 years old; Suggested no. of years to support for a girl = no.
				of years till she turns 22 years old; The mortality age is 88 years
				old for males and 92 years old for females, after adding an extra 10%
				for the purpose of financial planning. Source: Life Expectancy at
				Birth in Year 2013, Department of Statistics Singapore.</span>
		</div>
		<!-- 选择no时展示内容 -->
		<div v-show="dependants_list.is_dependants === 'N'" class="yesBox">
			<el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Please state reason" v-model="dependants_list.reason" size="large" @input="dependants_list.reason = $util.inputReg(dependants_list.reason)">
			</el-input>
		</div>
		<!-- details新增、编辑弹窗 -->
		<ElementDialog Title="Dependant" :Visible.sync="Dialog.visible">
			<el-form slot="content" :model="detailsForm" :rules="rules" ref="detailsForm" class="my-form">
				<el-row>
					<el-col :span="12">
						<el-form-item label="Name" prop="name" class="require">
							<el-input v-model="detailsForm.name" @input="detailsForm.name = $util.inputReg(detailsForm.name)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Gender" prop="gender" class="require">
							<el-select v-model="detailsForm.gender" placeholder="">
								<el-option label="Male" value="Male"></el-option>
								<el-option label="Female" value="Female"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Relationship" prop="relationship" class="require">
							<el-select v-model="detailsForm.relationship" placeholder="">
								<el-option v-for="item in relationshipOptions" :key="item.code" :label="item.name" :value="item.code" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="Date of Birth" prop="dob">
							<el-date-picker v-model="detailsForm.dob" type="date" format="dd/MM/yyyy" value-format="yyyy-MM-dd" placeholder="" />
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="Years to support" prop="years_to_support" class="require">
							<el-input v-model="detailsForm.years_to_support" type="number" @input="detailsForm.years_to_support = $util.inputReg(detailsForm.years_to_support)"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-input type="textarea" v-model="detailsForm.remarks" :autosize="{ minRows: 5, maxRows: 10}" placeholder="Remark" class='mt20px' style="width: 95%;" size="large" @input="detailsForm.remarks = $util.inputReg(detailsForm.remarks)"></el-input>
					</el-col>
				</el-row>
			</el-form>
			<div slot="BtnSolt" class="TextAlginCenter">
				<el-button type="warning" v-if="Dialog.status == 'Add'" @click="SaveAdd">Add</el-button>
				<el-button type="warning" v-if="Dialog.status == 'Edit'" @click="SaveUpdate">Update</el-button>
				<el-button type="warning" @click="Dialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
	</div>
</template>

<script>

import SwitchBoxVue from "../../../components/switchBox.vue";
import MyTitle from "../components/MyTitle.vue";
import ElementTable from "@/components/ElementTable.vue";
import ElementDialog from "../../../components/ElementDialog/index.vue";
import { setAllKYC } from "@/utils/kyc"
export default {
	components: { SwitchBoxVue, MyTitle, ElementTable, ElementDialog },
	data () {
		return {
			dependants_list: {
				dependants_details: [],
				reason: '',
				is_dependants: '',
				additional_notice: '',
			},
			// Dialog弹窗
			Dialog: {
				visible: false,
				status: "Add",
			},
			// 弹窗form表单数据
			detailsForm: {
				dependant_id: '',
				name: '',
				gender: '',
				relationship: '',
				dob: '',
				years_to_support: '',
				remarks: ''
			},
			relationshipOptions: global.relationship,
			rules: {
				name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
				gender: [{ required: true, message: 'Gender is required', trigger: 'blur' }],
				years_to_support: [{ required: true, message: 'Years to support is required', trigger: 'blur' }],
				relationship: [{ required: true, message: 'Relationship is required', trigger: 'blur' }],
			},
			currentItem: null,
			kycAllData: {},
			timer:null
		};
	},
	computed: {
		configData () {
			return [
				{
					slot: "operation"
				}, {
					label: '',
					type: 'index',
					fixed: 'left'
				}, {
					label: "Name",
					prop: "name"
				}, {
					label: "Gender",
					prop: "gender"
				}, {
					label: "Relationship",
					prop: "relationship"
				}, {
					label: "Date of Birth",
					prop: "dob"
				}, {
					label: "Years to support",
					prop: "years_to_support"
				}, {
					label: "Remark",
					prop: "remarks"
				}
			]
		},
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			setAllKYC(this.$store, this.currentItem)
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			if (this.timer) clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
					this.getDataFromAll(newVal)
				})
			}, 200)
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest')
		},
		// 给KYCAllData赋值
		setKYC (val) {
			// 1.3依赖人年龄赋值1.12
			let dpdtArr = this.dependants_list.dependants_details
			this.kycAllData.kyc.client[val].needs_analysis.education_fund.map(edu => {
				if (dpdtArr.filter(n => n.dependant_id == edu.dependant_id).length > 0) {
					edu.current_age = this.$getAgebyDate(dpdtArr.filter(n => n.dependant_id == edu.dependant_id)[0].dob)
				}
			})
			Object.keys(this.kycAllData.kyc.client[val].dependants_list).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].dependants_list[key] = this.dependants_list[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		// 删除亲属信息
		deleteDetails (row) {
			this.$confirm('confirm to delete?', 'Alert', {
				confirmButtonText: 'CONFIRM',
				cancelButtonText: 'CANCEL',
				type: "info",
			}).then(() => {
				// 删除逻辑
				this.dependants_list.dependants_details.map((temp, index) => {
					if (temp.dependant_id === row.dependant_id) {
						this.dependants_list.dependants_details.splice(index, 1)
					}
				})
			}).catch(() => { })
		},
		// 新增亲属信息
		AddDetails () {
			this.detailsForm = this.$ResetFrom(this.detailsForm)
			this.detailsForm.dob = this.$getCurrentDate()
			this.detailsForm.dependant_id = Date.now().toString()
			this.openDialog('Add')
		},
		// 编辑亲属信息
		editDetails (row) {
			this.detailsForm = row
			this.openDialog('Edit')
		},
		// 打开弹窗
		openDialog (status) {
			this.Dialog.visible = true
			this.Dialog.status = status
		},
		// 新增
		SaveAdd () {
			this.$refs.detailsForm.validate(valid => {
				if (valid) {
					this.dependants_list.dependants_details.push(this.detailsForm)
					this.Dialog.visible = false
				}
			})
		},
		// 编辑
		SaveUpdate () {
			this.Dialog.visible = false
		},
		getDataFromAll (val) {
			Object.keys(this.dependants_list).map(key => {
				this.dependants_list[key] = this.kycAllData.kyc.client[val].dependants_list[key]
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.currentItem = this.$store.state.KYC.kycPageItem
		this.getDataFromAll(this.kycClient)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () { },
	destroyed () { // this.setKYC(this.kycClient)
		setAllKYC(this.$store, this.currentItem)
	}
}
</script>
<style lang='css' scoped>
p {
	color: #6c6c6c;
	font-size: 22px;
	margin-bottom: 20px;
}
.yesBox {
	padding: 30px 0;
}
span {
	line-height: 24px;
}
.circle {
	margin: 10px;
	cursor: pointer;
	margin-left: auto;
}
</style>