/*
 *@Author: 段彩翔
 *@Date: 2022-04-12 14:41:27
 *@Description: KYC & FNA
*/
<template>
	<div class='displayFlex flexColumn h100 boxSizing'>
		<!-- 顶部 -->
		<div class="topBox displayFlex flexAlignCenter flexJCspaceBetween">
			<div class="displayFlex flexColumn pointer" @click="openProcessPage">
				<div class="displayFlex flexAlignCenter">
					<i class="el-icon-s-operation"></i>
					<span style="margin-left: 200px;">KYC & FNA</span>
					<span style="margin-left: 200px;">Recommendations</span>
					<span style="margin-left: 200px;">Declarations</span>
				</div>
				<div class="ml50ox">
					<p ref="progressBack" style="border-bottom: 6px solid #c1c1c1"></p>
					<p class="progressbar" ref="progressTop"></p>
				</div>
			</div>
			<el-dropdown @command="handleCommand" trigger="click" size="medium">
				<span class="el-dropdown-link">
					<i class="el-icon-more pointer mr20"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<!-- <el-dropdown-item command="Invite">Invite</el-dropdown-item> -->
					<el-dropdown-item command="Preview">Preview</el-dropdown-item>
					<el-dropdown-item command="Download">Download</el-dropdown-item>
					<el-dropdown-item command="Cancel">Cancel</el-dropdown-item>
					<el-dropdown-item divided>
						<el-checkbox v-model="checked" v-show="stateName === 'ClientEdit'">Auto Save</el-checkbox>
					</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
		<!-- 标题、名称 -->
		<NavbarVue backgroundColor="#e8e8e8" fontSize="24px" className="flexBox">
			<span class="w50">{{title}}</span>
			<el-radio-group v-model="clientName" @change="clientChange" class="w40" size="medium" fill="#FF9941">
				<el-radio-button v-for="(item, index) in clientNames" :label="item" :key="index"></el-radio-button>
			</el-radio-group>
			<div class="displayFlex flexAlignCenter" style="width: 250px;">
				<i class="el-icon-refresh mr30px pointer" style="font-weight: bold;font-size: 30px;" @click="refresh"></i>
				<!-- <el-button @click="switchState" size="medium" plain>{{stateName}}</el-button> -->
				<el-button @click="nextBtnRequest('Save')" style="margin-left: 30px;" size="medium" plain>Save</el-button>
			</div>
		</NavbarVue>
		<!-- 内容 -->
		<component :is="list[item]" :ref="list[item]" @nextBtnRequest="nextBtnRequest"></component>
		<!-- 底部按钮 -->
		<div class="p20px TextAlginRight bottomClass" v-show="bottomBntShow">
			<el-button type="warning" size="medium" @click="AddNotes" v-if="!ifExistsNote && item !== 12">+ Add Notes</el-button>
			<el-button type="warning" size="medium" plain @click="AddNotes" v-if="ifExistsNote && item !== 12"><i class="el-icon-warning-outline"></i> Add Notes</el-button>
			<el-button type="warning" size="medium" @click="previousBtn" v-if="item !== 0" style="font-weight: bold;">Previous</el-button>
			<el-button type="warning" size="medium" @click="nextBtn" v-if="(item === 8 && $store.state.KYC.CKA !== -1) || (item === 9 && $store.state.KYC.Grade)" style="padding: 10px 30px;font-weight: bold;">Next</el-button>
			<el-button type="warning" size="medium" @click="nextBtn" v-if="item !== 8 && item !== 9 && item !== 16 && item !== 13 && item !== 15" style="padding: 10px 30px;font-weight: bold;">Next</el-button>
			<el-button type="warning" size="medium" @click="submitBtn" v-if="(item === 8 && $store.state.KYC.CKA === -1) || (item === 9 && !($store.state.KYC.Grade))">Submit</el-button>
			<el-button size="medium" @click="submitBtn" v-if="item === 13">Submit</el-button>
			<el-button type="warning" size="medium" @click="$router.push('/Presentation')" v-if="item == 13">Preview</el-button>
			<el-button type="warning" size="medium" @click="handleCommand('Preview')" v-if="item == 15">Preview PDF</el-button>
			<el-button type="warning" size="medium" @click="lockKYC" v-if="item == 15">Lock for E-Sub</el-button>

		</div>
		<!-- 流程页面弹框 -->
		<ProcessVue :showProcessPage.sync="showProcessPage" :currentData="currentData" @changePage="changePage" @openLockPage="openLockPage" ref="processRef" />
		<!-- copy link dialog -->
		<ElementDialog :Visible.sync="Dialog.visible" Width="40">
			<div slot="content">
				<p>Invite your client to work on KYC together</p>
				<img :src="require('@/assets/img/copy.jpg')" alt="" width=100px height=100px @click="clickImg" style="cursor: pointer;">
			</div>
			<div slot="BtnSolt">
				<el-button @click="Dialog.visible = false">Cancel</el-button>
			</div>
		</ElementDialog>
		<!-- note -->
		<NotesView ref="noteView" @setNote="setNote" :noteValue="noteValue"></NotesView>
		<!-- 3.3节点 -->
		<SignSubmit v-if="showSignSubmit" @changePageByClick="changePageByClick" />
		<!-- 3.3 锁定弹窗 -->
		<ConfirmDialog
			:dialogVisible="showDialog"
			@confirm="handleConfirm"
			@cancel="handleCancel"
		></ConfirmDialog>
	</div>
</template>

<script>
import ConfirmDialog from '../components/ConfirmDialog'
import NavbarVue from "@/components/Navbar.vue";
import ProcessVue from "../Process.vue";
import PersonalInformationVue from "./PersonalInformation.vue";
import TrustedIndividualsVue from "./TrustedIndividuals.vue"
import DependantsVue from "./Dependants.vue"
import FinancialObjectivesVue from "./FinancialObjectives.vue"
import AssetsLiabilitiesView from "./AssetsLiabilitiesView.vue"
import CashflowView from "./CashflowView.vue";
import ExistingInvestmentsInsuranceView from "./ExistingInvestmentsInsuranceView.vue";
import BudgetView from "./BudgetView.vue";
import CustomerKnowledgeAssessmentView from "./CustomerKnowledgeAssessmentView.vue";
import RiskProfileQuestionnaireView from "./RiskProfileQuestionnaireView.vue";
import AccreditedInvestorDeclarationView from "./AccreditedInvestorDeclarationView.vue";
import NeedAnalysisView from "./NeedAnalysisView.vue";
import { SwitchingReplacementPolicy, ClientAcknowledgementAndDeclaration, SignSubmit } from "../Declarations/index"
import { SummaryOfKYC, ProductRecommendations } from "../Recommendations/index"
// api
import { editKyc, kycUpdate, kycUpdateNOLoading, editAuth, createKYCLink, creatKycPdf, updateLockStatus, miSignRequest } from '@/api/KYC'
import ElementDialog from "@/components/ElementDialog/index.vue";
import NotesView from "../components/NotesView.vue";
import { WhetherCompleted, getSystemPrompts } from "@/utils/kyc"
import { SET_AutoSave, GET_AutoSave, Remove_AutoSave } from '@/utils/localStorage'
// Recommendations
export default {
	components: {
		ConfirmDialog,
		NavbarVue,
		ProcessVue,
		PersonalInformationVue,
		TrustedIndividualsVue,
		DependantsVue,
		FinancialObjectivesVue,
		AssetsLiabilitiesView,
		CashflowView,
		ExistingInvestmentsInsuranceView,
		BudgetView,
		CustomerKnowledgeAssessmentView,
		RiskProfileQuestionnaireView,
		AccreditedInvestorDeclarationView,
		NeedAnalysisView,
		SummaryOfKYC,
		ProductRecommendations,
		SwitchingReplacementPolicy,
		ClientAcknowledgementAndDeclaration,
		SignSubmit,
		ElementDialog,
		NotesView
	},
	data () {
		return {
			showDialog: false,
			current_kyc_no: '',
			list: [PersonalInformationVue, TrustedIndividualsVue, DependantsVue, FinancialObjectivesVue, AssetsLiabilitiesView,
				CashflowView, ExistingInvestmentsInsuranceView, BudgetView, CustomerKnowledgeAssessmentView, RiskProfileQuestionnaireView,
				AccreditedInvestorDeclarationView, NeedAnalysisView, SummaryOfKYC, ProductRecommendations, SwitchingReplacementPolicy,
				ClientAcknowledgementAndDeclaration, SignSubmit],
			objList: [
				'personal_information', 'trusted_individual', 'dependants_list', 'financial_objectives', 'assets_liabilities',
				'cashflow', 'existing_investments_insurance', 'budget', 'customer_knowledge_assessment', 'risk_profile_questionnaire',
				'accredited_investor_declaration', 'needs_analysis', 'summary_of_KYC', 'basis_recommendations', 'switching_replacement_policy',
				'client_acknowledgement_declaration'
			],
			item: this.$store.state.KYC.kycPageItem,
			showProcessPage: false,
			title: '1.1 Personal Information',
			moreAction: false,
			// Auto Save 是否选中
			checked: true,
			// 状态名称
			stateName: 'ClientEdit',
			// 客户名称
			clientName: '',
			// 客户名称集合
			clientNames: [],
			kycAllData: {},
			// Dialog弹窗
			Dialog: {
				visible: false,
				status: "Add",
			},
			// 自动保存 定时器
			timer: null,
			// 是否存在note false 不存在 true 存在
			ifExistsNote: false,
			noteValue: '',
			showSignSubmit: false,
			// 各节点完成情况
			currentData: null
		};
	},
	computed: {
		bottomBntShow () {
			return this.$store.state.KYC.bottomBntShow
		}
	},
	watch: {
		item (val, oldval) {
			this.getNoteFromVuex(val)
			this.getTitleFromProcess(val)
			// 切换节点需要调用一次保存接口
			kycUpdate(this.$store.state.KYC.kycAllData).then(() => { })
		},
		checked (val) {
			this.IntervalHandler(val)
			SET_AutoSave(val)
		}
	},
	methods: {
		// 刷新
		async refresh () {
			if (this.item === 13) {
				let res = await editKyc({ kyc_no: this.$store.state.KYC.kycAllData.kyc.kyc_no })
				this.$store.commit('SET_KYC_ALL_DATA', { kyc: res.content })
			}
			this.$router.go(0)
		},
		// 条件1.this.checked为true 2.this.stateName === 'ClientEdit'
		IntervalHandler (checked) {
			if (checked) {
				// 每隔60秒自动保存一次KYC数据
				this.timer = setInterval(() => {
					kycUpdateNOLoading(this.$store.state.KYC.kycAllData).then(() => { })
				}, 60000)
			} else {
				// 如果存在定时器，则删除
				clearInterval(this.timer)
			}
		},
		// ClientEdit/RepEdit  切换
		switchState () {
			let edit_authority = ''
			if (this.stateName === 'ClientEdit') {
				this.stateName = 'RepEdit'
				edit_authority = '1'
			} else {
				this.stateName = 'ClientEdit'
				edit_authority = '0'
			}
			editAuth({ kyc_no: this.kycAllData.kyc.kyc_no, edit_authority: edit_authority }).then()
			this.IntervalHandler(this.checked, this.stateName)
		},
		// 打开进度条
		openProcessPage (val) {
			if (val !== 'mounted') {
				this.showProcessPage = !this.showProcessPage
				// 保存当前页面数据
				this.$refs[this.list[this.item]].setKYC(this.$store.state.KYC.kycClient)
			}
			this.currentData = WhetherCompleted(this.$store)
			// 设置进度条
			let count = 0
			this.currentData.map(res => {
				if (res.data.length === 0) {
					count += 1
				}
			})
			let width = (count + 1) / 15 * this.$refs.progressBack.offsetWidth
			this.$refs.progressTop.style.width = width + 'px'
		},
		// 两个用户时切事件
		clientChange (val) {
			// kycClient
			this.$store.commit('SET_KYCCLIENT', this.clientNames.indexOf(val))
		},
		// 点击图片 复制kyc_link
		clickImg () {
			let url = this.kycAllData.kyc.kyc_link
			//新建一个文本框
			let oInput = document.createElement('input')
			//赋值给文本框
			oInput.value = url;
			document.body.appendChild(oInput)
			// 选择对象;
			oInput.select();
			// 执行浏览器复制命令
			document.execCommand("Copy")
			//复制完成删除掉输入框
			oInput.remove()
			this.$message.success('Copy link successfully')
			this.Dialog.visible = false
		},
		// 点击更多操作触发的事件回调
		handleCommand (val) {
			// 保存当前页面数据
			this.$refs[this.list[this.item]].setKYC(this.$store.state.KYC.kycClient)
			let kyc = this.$store.state.KYC.kycAllData
			kyc.kyc.rep = this.$store.state.User.Rep
			if (val === 'Invite') {
				// 判断是否存在kyc_link，若有，则显示复制连接；否则，请求接口获取链接
				if (!this.kycAllData.kyc.kyc_link) {
					this.Dialog.visible = true
				} else {
					this.$confirm('You are starting Nf2f KYC process?', '', {
						confirmButtonText: 'Create Link',
						cancelButtonText: 'Cancel',
					}).then(() => {
						let param = {
							kyc_no: this.kycAllData.kyc.kyc_no,
							agent_code: this.kycAllData.kyc.agent_id,
							client_a: this.kycAllData.kyc.client[0].client_id,
							client_b: this.kycAllData.kyc.client.length > 1 ? this.kycAllData.kyc.client[1].client_id : ''
						}
						createKYCLink(param).then(res => {
							this.kycAllData.kyc.kyc_link = res.content.share_link
							this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
							this.Dialog.visible = true
						})
					}).catch(() => { })
				}
			}
			if (val === 'Cancel') {
				Remove_AutoSave()
				localStorage.removeItem('systemPrompts')
				this.$router.push('/')
			}
			if (val === 'Preview') {
				creatKycPdf(kyc).then(res => {
					if (res.content) {
						localStorage.setItem('PDFUrl', res.content)
						const { href } = this.$router.resolve({
							path: '/Preview'
						});
						window.open(href, '_blank');
					}
				})
			}
			if (val === 'Download') {
				creatKycPdf(kyc).then(res => {
					if (res.content) window.open(res.content)
				})
			}
		},
		// 修改当前节点
		changePage () {
			if (!this.$store.state.KYC.bottomBntShow) this.$store.commit('SET_BOTTOMBTN', true)
			this.item = this.$store.state.KYC.kycPageItem
		},
		// 3.3 不修改节点，有未完成节点则打开dialog，否则锁定KYC
		openLockPage () {
			// 保存数据
			this.nextBtnRequest('Save', 'showSignSubmit')
		},
		// 3.3 点击跳转
		changePageByClick (val) {
			this.$store.commit('SET_KYCPAGEITEM', val)
			this.item = val
		},
		// 添加笔记
		AddNotes () {
			this.$refs.noteView.dialogVisible = true
		},
		getNoteFromVuex (val) {
			// 2.1\3.3节点没有备注功能
			if (val && val !== 12 && val !== 16 || val === 0) {
				this.noteValue = this.kycAllData.kyc.client[this.$store.state.KYC.kycClient][this.objList[val]].additional_notice
				this.ifExistsNote = this.noteValue !== ''
			}
		},
		setNote (val) {
			this.noteValue = val
			// 设置Notes，及时保存，不需要在页面单独设置，所以页面的setkyc 排除notes赋值
			let objKey = this.objList[this.item]
			this.kycAllData.kyc.client[this.$store.state.KYC.kycClient][objKey].additional_notice = val
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
			if (val) {
				this.ifExistsNote = true
			} else {
				this.ifExistsNote = false
			}
		},
		previousBtn () {
			this.$refs[this.list[this.item]].setKYC(this.$store.state.KYC.kycClient)
			if (this.item === 12 && this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].accredited_investor_declaration.is_ai === 'Y') {
				this.item -= 2
			} else {
				this.item -= 1
			}
			this.$store.commit('SET_KYCPAGEITEM', this.item)
		},
		nextBtn () {
			// 当前所在页面组件
			let componentRef = this.$refs[this.list[this.item]]
			// 判断当前所在页面form表单验证是否通过
			componentRef.submitForm()
		},
		submitBtn () {
			// 当前所在页面组件
			let componentRef = this.$refs[this.list[this.item]]
			// 判断当前所在页面form表单验证是否通过
			componentRef.submit_Form()
		},
		// KYC保存接口- 如果点击右上角save按钮，步骤：1从当前页面获取最新kyc数据 2保存
		nextBtnRequest (item, show) {
			console.log('KYC save or next');
			if (item === 'Save') {
				this.$refs[this.list[this.item]].setKYC(this.$store.state.KYC.kycClient)
			}
			
			setTimeout(() => {
				kycUpdate(this.$store.state.KYC.kycAllData).then(res => {
					// 如果AI高亮，跳过1.12节点
					if (item === 10 && this.kycAllData.kyc.client[this.$store.state.KYC.kycClient].accredited_investor_declaration.is_ai === 'Y') {
						this.item += 2
					} else if (item !== 8 && item !== 'Save' || item === 8 && this.$store.state.KYC.CKA !== -1) {
						this.item += 1
					}
					this.$store.commit('SET_KYCPAGEITEM', this.item)
					if (show === 'showSignSubmit') this.lockKYC()
				})
			}, 500)
		},
		// 校验2.2节点
		vertifyProduct (val, result) {
			result = true
			this.kycAllData.kyc.client[val].basis_recommendations.product_recommendations_option.every((proposal, i) => {
				proposal.product_list.every((product, j) => {
					let a = getSystemPrompts({ proposal: i, product: j }, this.$store, this.$confirm)
					result = a
					return a
				})
			})
			return result
		},
		// 锁定KYC
		lockKYC () {
			// 保存当前页面数据
			this.$refs[this.list[this.item]].setKYC(this.$store.state.KYC.kycClient)
			// 锁定接口参数
			let kyc = this.$store.state.KYC.kycAllData
			// 如果有未完成的节点，展示signsubmit页面，
			this.currentData = WhetherCompleted(this.$store)
			let count = 0
			this.currentData.map(res => {
				if (res.data.length !== 0) count += 1
			})
			// 如果2.2节点不符合条件，弹提示框
			// 否则弹窗确认是否锁定
			let result = this.vertifyProduct(0, true)
			if (this.clientNames.length > 1) {
				result = this.vertifyProduct(1, true)
			}
			if (count !== 0) {
				this.showSignSubmit = true
			} else if (result) {
				this.$confirm('Do you want to lock the KYC?', 'Alert', {
					confirmButtonText: 'Confirm',
					cancelButtonText: 'Cancel',
					closeOnClickModal: false,
					closeOnPressEscape: false,
					showClose: false
				}).then(() => {
					this.$confirm('Do you want to send the email to customers?', 'Alert', {
						confirmButtonText: 'Confirm',
						cancelButtonText: 'Cancel',
						closeOnClickModal: false,
						closeOnPressEscape: false,
						showClose: false
					}).then(() => {
						kyc.kyc.whether_to_send_an_Email = 'Y'
						this.lockKYC_Request(kyc)
					}).catch(() => {
						kyc.kyc.whether_to_send_an_Email = 'N'
						this.lockKYC_Request(kyc)
					})
				}).catch(() => { })
			}
		},
		lockKYC_Request (kyc) {
			kyc.kyc.rep = this.$store.state.User.Rep
			kyc.kyc.submit_audit_status = '15'
			updateLockStatus(kyc).then(() => {
				this.lockConfirm(kyc.kyc.kyc_no)
			}).catch(() => {
				clearInterval(this.timer)
			})
		},
		lockConfirm (kyc_no) {
			this.showDialog = true
			this.current_kyc_no = kyc_no
			clearInterval(this.timer)
		},
		handleConfirm() {
      // 在这里执行确认后的操作
      console.log('跳转到签字页');
      // 跳转到签字页
			miSignRequest({ kyc_no: this.current_kyc_no }).then(res => {
				if (res.content.redirectURL) window.open(res.content.redirectURL, '_blank')
			})
    },
    handleCancel() {
      // 在这里执行取消后的操作
      console.log('跳转到search模块');
			// 跳转到search模块
			this.$router.push('/')
      // 关闭确认弹框
      this.showDialog = false;
    },
		// 获取当前页面title
		getTitleFromProcess (temp) {
			let arr = []
			if (temp < 12) {
				arr = this.$refs.processRef.activities
			} else if (12 <= temp && temp < 14) {
				temp -= 12
				arr = this.$refs.processRef.activities1
			} else {
				temp -= 14
				arr = this.$refs.processRef.activities2
			}
			arr.map(res => {
				if (temp + 1 === res.index) {
					this.title = res.level + '.' + res.index + ' ' + res.title
				}
			})
		},
		setClientName () {
			this.clientNames = []
			this.$store.state.KYC.kycAllData.kyc.client.map(res => {
				this.clientNames.push(res.personal_information.surname)
			})
			this.$store.commit('SET_CLIENTNAME', this.clientNames)
			this.clientName = this.clientNames[this.$store.state.KYC.kycClient]
			console.log(this.clientName, 'clientNames', this.clientNames);
		},
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		if (GET_AutoSave() !== null) this.checked = !(GET_AutoSave() === 'false')
		if (JSON.stringify(this.$store.state.KYC.kycAllData) == '{}') this.$route.push('/login')
		//从rider、fund、main plan跳转到2.2节点 不用获取最新数据
		// 1.5\1.6\1.7节点 子页面数据存储在vuex中并没有调用保存接口，如果获取最新，则会丢失子页面数据
		if (this.item !== 13 && this.item !== 4 && this.item !== 5 && this.item !== 6 && this.item !== 11) {
			editKyc({ kyc_no: this.$store.state.KYC.kycAllData.kyc.kyc_no }).then(res => {
				// 1.存储kyc数据 2.存储当前页数 3.跳转页面
				this.$store.commit('SET_KYC_ALL_DATA', { kyc: res.content })
			})
		}
		this.changePage()
		// 获取kyc数据
		this.kycAllData = this.$store.state.KYC.kycAllData
		// 获取名称
		this.setClientName()
		// 获取笔记
		this.getNoteFromVuex(this.item)
	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {
		this.getTitleFromProcess(this.item)
		// 设置定时器
		this.IntervalHandler(this.checked)
		// 设置进度条
		this.openProcessPage('mounted')
	},
	destroyed () {
		clearInterval(this.timer)
	}
}
</script>
<style lang='scss' scoped>
.topBox {
	color: #9a9a9a;
	padding-right: 20px;
}
.topBox i {
	margin-left: 10px;
	font-size: 40px;
	line-height: 50px;
	color: #707070;
}
.wrapper {
	flex-grow: 1;
	flex-basis: 100%;
	overflow-y: scroll;
	height: 400px;
}
.el-checkbox .el-checkbox__label {
	font-size: 18px;
}
.bottomClass {
	border-top: 1px solid #c7c6c6;
}
p {
	font-size: 20px;
	line-height: 30px;
	color: #f36a10;
}
.progressbar {
	border-bottom: 6px solid #ff9941;
	position: absolute;
	width: 100px;
	top: 50px;
}
</style>