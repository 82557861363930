<template>
	<div class=''>
		<el-radio-group v-model="radio" @change="changeRadio" size="medium" :class="type === 1 ? '' : 'big'">
			<!-- 单选 选项为 H　M L NA -->
			<template v-if="type === 1">
				<el-radio-button label="H" :class="radio === 'H' ? 'H' : 'activeH'"></el-radio-button>
				<el-radio-button label="M" :class="radio === 'M' ? 'M' : 'activeM'"></el-radio-button>
				<el-radio-button label="L" :class="radio === 'L' ? 'L' : 'activeL'"></el-radio-button>
				<el-radio-button label="NA" :class="radio === 'NA' ? 'NA' : 'activeNA'"></el-radio-button>
			</template>
            <!-- 单选 选项为 YES NO NA -->
			<template v-if="type === 2">
				<el-radio-button label="YES" :class="radio === 'Y' || radio === 'YES' ? 'L' : 'activeL'"></el-radio-button>
				<el-radio-button label="NO" :class="radio === 'N' || radio === 'NO' ? 'H' : 'activeH'"></el-radio-button>
				<el-radio-button label="NA" :class="radio === 'NA' ? 'NA' : 'activeNA'"></el-radio-button>
			</template>
		</el-radio-group>
	</div>
</template>

<script>

export default {
	components: {},
	props: {
		status: {
			type: String,
			default: 'H'
		},
        // 单选类型  1:H　M L NA   2:yes no NA
        type: {
            type: Number,
            default: 1
        }
	},
	data() {
		return {
			radio: ''
		};
	},
	computed: {},
	watch: {
		status () {
			this.radio = this.status
		}
	},
	methods: {
		changeRadio(val) {
			if (this.type === 1) {
				this.$emit('update:status', val)
			} else {
				let status = val === 'Y' || val === 'YES' ? 'Y' : val === 'N' || val === 'NO' ? 'N' : 'NA'
				this.$emit('update:status', status)
			}
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created() {

	},
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted() {
		this.radio = this.status
	},
}
</script>
<style lang='css' scoped>
.el-radio-group >>> .el-radio-button__inner {
	font-weight: bold;
	width: 50px;
}
.big >>> .el-radio-button__inner {
	font-weight: bold;
	width: 100px;
    font-size: 18px;
}
.el-radio-group >>> .H .el-radio-button__inner {
	color: #FFFFFF;
	background-color: #d65555;
	border-color: #d65555;
}
.el-radio-group >>> .activeH .el-radio-button__inner {
	color: #d65555;
}
.el-radio-group >>> .M .el-radio-button__inner {
	background-color: #f87f18;
	border-color: #f87f18;
}
.el-radio-group >>> .activeM .el-radio-button__inner {
	color: #f87f18;
}
.el-radio-group >>> .L .el-radio-button__inner {
	color: #FFFFFF;
	background-color: #539fea;
	border-color: #539fea;
}
.el-radio-group >>> .activeL .el-radio-button__inner {
	color: #539fea;
}
.el-radio-group >>> .NA .el-radio-button__inner {
	background-color: #b3b3b3;
	border-color: #b3b3b3;
}
.el-radio-group >>> .activeNA .el-radio-button__inner {
	color: #6C6C6C;
}
</style>