<template>
	<div class='wrapper'>
		<!-- 1  --------------------------------------------------------------------------------------- -->
		<p style="margin-top: 0px;">{{paragraph1}}</p>
		<p>{{paragraph2}}</p>
		<SwitchBox :status.sync="client_acknowledgement_declaration.is_agree" width="200px" class="mt20px" />
		<!-- status_one值为no时显示 -->
		<template v-if="client_acknowledgement_declaration.is_agree === 'N'">
			<p>I am/we are aware that it is my/our responsibility to ensure the suitability of the product(s) selected and wish to make the following amendment(s):</p>
			<MyInput placeholder="Please provide details" :textareaValue.sync="client_acknowledgement_declaration.wish_amendment" />
		</template>
		<!-- 2a --------------------------------------------------------------------------------------- -->
		<p>{{paragraph3}}</p>
		<p>{{paragraph4}}</p>
		<SwitchBox :status.sync="client_acknowledgement_declaration.is_investment_linked_plan" width="200px" class="mt20px" />
		<!-- 2b is_investment_linked_plan值为yes时显示--------------------------------------------------------------------------------------- -->
		<template v-if="client_acknowledgement_declaration.is_investment_linked_plan === 'Y'">
			<p>{{paragraph_second_b}}</p>
			<SwitchBox :status.sync="client_acknowledgement_declaration.is_experience" width="200px" class="mt20px" />
			<!-- 2c is_investment_linked_plan值为yes时显示--------------------------------------------------------------------------------------- -->
			<p>{{paragraph_second_c}}</p>
			<p>My/Our representative has confirmed and informed me/us of the following:</p>
			<p class="mb20px">I am/We are aware that it is my/our responsibility to ensure the suitability of the ILP/CIS/ unlisted SIP chosen.</p>
			<ElementRadiogroup :type="2" :status.sync="client_acknowledgement_declaration.is_aware_ilp_suitability" />
			<p class="mb20px">I/We understand that Infinitum may be contacting me/us to confirm this purchase.</p>
			<ElementRadiogroup :type="2" :status.sync="client_acknowledgement_declaration.is_understand_contacting_income" />
			<p class="mb20px">I/We understand that this application is subject to approval by Infinitum’s senior management. (If I/we have been accessed not to have
				the relevant knowledge in ILP/CIS/ unlisted SIP.</p>
			<ElementRadiogroup :type="2" :status.sync="client_acknowledgement_declaration.is_understand_approval" />
		</template>
		<!-- 3  --------------------------------------------------------------------------------------- -->
		<p>{{paragraph_third}}</p>
		<!-- 多选 -->
		<CheckBox ref="listForm" checkBoxType="listForm" :content="checkBoxContent" :checkedData="checkedData_Three" @changeStatus="changeStatus" />
		<!-- 全选按钮 -->
		<el-button type="warning" size="medium" @click="CheckAll">Check All</el-button>
		<!-- 4  --------------------------------------------------------------------------------------- -->
		<p>{{paragraph_fourth}}</p>
		<!-- 5  --------------------------------------------------------------------------------------- -->
		<p>{{paragraph_fifth}}</p>
		<p>{{paragraph_fifth_content}}</p>
		<SwitchBox :status.sync="client_acknowledgement_declaration.is_read_understood" width="200px" class="mt20px" />
		<!-- 6  --------------------------------------------------------------------------------------- -->
		<p>{{paragraph_sixth}}</p>
		<p>{{paragraph_sixth_content}}</p>
		<CheckBox checkBoxType="multipleNoBorder" :content="checkBoxContent_six" :checkedData="checkedData_six" distinguish="2" @changeStatus="changeStatus" />
		<p v-html="paragraph_sixth_content1"></p>
		<!-- 7  --------------------------------------------------------------------------------------- -->
		<p>{{paragraph_seventh}}</p>
		<p>{{paragraph_seventh_content}}</p>
		<p>Is there a Beneficial Ownership arrangement?</p>
		<SwitchBox :status.sync="client_acknowledgement_declaration.is_beneficial_ownership_arrangement" width="200px" class="mt20px" />
		<!-- form -->
		<el-form :model="client_acknowledgement_declaration" :rules="rules" ref="personalForm1" class="mt20px my-form" label-position="left" v-if="client_acknowledgement_declaration.is_beneficial_ownership_arrangement === 'Y'">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Name of Beneficial Owner" prop="beneficial_owner_name">
						<el-input v-model="client_acknowledgement_declaration.beneficial_owner_name" @input="client_acknowledgement_declaration.beneficial_owner_name = $util.inputReg(client_acknowledgement_declaration.beneficial_owner_name)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="NRIC/Passport number/FIN" prop="owner_nric_passportno_fin">
						<el-input v-model="client_acknowledgement_declaration.owner_nric_passportno_fin" @input="client_acknowledgement_declaration.owner_nric_passportno_fin = $util.inputReg(client_acknowledgement_declaration.owner_nric_passportno_fin)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Date of Birth (dd/mm/yyyy)" prop="owner_birth">
						<el-date-picker v-model="client_acknowledgement_declaration.owner_birth" type="date" format="dd/MM/yyyy" value-format="yyyy-MM-dd" placeholder="" />
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Gender" prop="owner_gender">
						<el-select v-model="client_acknowledgement_declaration.owner_gender" placeholder="" no-data-text="No Data">
							<el-option label="Male" value="Male"></el-option>
							<el-option label="Female" value="Female"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Relationship to Proposer" prop="owner_proposer_relationship">
						<el-input v-model="client_acknowledgement_declaration.owner_proposer_relationship" @input="client_acknowledgement_declaration.owner_proposer_relationship = $util.inputReg(client_acknowledgement_declaration.owner_proposer_relationship)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Nationality" prop="owner_nationality_type">
						<el-select v-model="client_acknowledgement_declaration.owner_nationality_type" placeholder="" no-data-text="No Data">
							<el-option v-for="item in nationalityOptions" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- 8  --------------------------------------------------------------------------------------- -->
		<p>{{paragraph_eighth}}</p>
		<p>{{paragraph_eighth_content}}</p>
		<p>{{paragraph_eighth_content1}}</p>
		<SwitchBox :status.sync="client_acknowledgement_declaration.is_pep" width="200px" class="mt20px" />
		<!-- form -->
		<el-form :model="client_acknowledgement_declaration" class="mt20px my-form" label-position="left" v-if="client_acknowledgement_declaration.is_pep === 'Y'">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Name of PEP" prop="pep_name">
						<el-input v-model="client_acknowledgement_declaration.pep_name" @input="client_acknowledgement_declaration.pep_name = $util.inputReg(client_acknowledgement_declaration.pep_name)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Title of PEP" prop="pep_title">
						<el-input v-model="client_acknowledgement_declaration.pep_title" @input="client_acknowledgement_declaration.pep_title = $util.inputReg(client_acknowledgement_declaration.pep_title)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Name of person related to PEP" prop="pep_related_person_name" class="prefixLeft">
						<el-input v-model="client_acknowledgement_declaration.pep_related_person_name" @input="client_acknowledgement_declaration.pep_related_person_name = $util.inputReg(client_acknowledgement_declaration.pep_related_person_name)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Relationship to PEP" prop="pep_relationship">
						<el-input v-model="client_acknowledgement_declaration.pep_relationship" @input="client_acknowledgement_declaration.pep_relationship = $util.inputReg(client_acknowledgement_declaration.pep_relationship)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- 9  --------------------------------------------------------------------------------------- -->
		<p>9) Payor Details</p>
		<p>The Payor refers to the person making the premium payment. Is the Proposer the Payor?</p>
		<SwitchBox :status.sync="client_acknowledgement_declaration.is_proposer_payor" width="200px" class="mt20px" />
		<!-- form -->
		<el-form :model="client_acknowledgement_declaration" ref="personalForm1" class="mt20px my-form" label-position="left" v-if="client_acknowledgement_declaration.is_proposer_payor === 'N'">
			<el-row>
				<el-col :span="12">
					<el-form-item label="Payor name (as in NRIC/Passport)" prop="payor_name">
						<el-input v-model="client_acknowledgement_declaration.payor_name" @input="client_acknowledgement_declaration.payor_name = $util.inputReg(client_acknowledgement_declaration.payor_name)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="NRIC/Passport number/FIN" prop="payor_nric_passportno_fin">
						<el-input v-model="client_acknowledgement_declaration.payor_nric_passportno_fin" @input="client_acknowledgement_declaration.payor_nric_passportno_fin = $util.inputReg(client_acknowledgement_declaration.payor_nric_passportno_fin)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Occupation" prop="payor_occupation">
						<el-input v-model="client_acknowledgement_declaration.payor_occupation" @input="client_acknowledgement_declaration.payor_occupation = $util.inputReg(client_acknowledgement_declaration.payor_occupation)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item :label="'Please state reason for paying \n the premiums on behalf of Proposer'" prop="pay_reason" class="prefixLine">
						<el-input v-model="client_acknowledgement_declaration.pay_reason" @input="client_acknowledgement_declaration.pay_reason = $util.inputReg(client_acknowledgement_declaration.pay_reason)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="12">
					<el-form-item label="Relationship to Proposer" prop="payor_proposer_relationship">
						<el-select v-model="client_acknowledgement_declaration.payor_proposer_relationship" placeholder="" no-data-text="No Data">
							<el-option v-for="item in relationship" :key="item.code" :label="item.name" :value="item.code" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12" v-if="client_acknowledgement_declaration.payor_proposer_relationship === 'Others'">
					<el-form-item label="Others" prop="payor_others_name">
						<el-input v-model="client_acknowledgement_declaration.payor_others_name" placeholder="Others name" @input="client_acknowledgement_declaration.payor_others_name = $util.inputReg(client_acknowledgement_declaration.payor_others_name)"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<!-- 10  -------------------------------------------------------------------------------------- -->
		<p>10) Source Of Funds</p>
		<p>What is the source of funds used to pay the premiums?</p>
		<CheckBox checkBoxType="listForm" :content="checkBoxContent_ten" :checkedData="checkedData_ten" distinguish="3" @changeStatus="changeStatus" />
		<p>If currently not employed, please provide details below(for example: previous employment, allowance from family members)</p>
		<MyInput placeholder="Please provide details" :textareaValue.sync="client_acknowledgement_declaration.not_employed_details" />
		<!-- My Next Review -->
		<MyTitle Title="My Next Review" class="mt20px" />
		<el-form :model="client_acknowledgement_declaration" class="mt20px my-form" label-position="left">
			<el-row>
				<el-col :span="24">
					<el-form-item :label="'Suggested date of next \n financial review'" prop="next_review_year">
						<el-date-picker v-model="client_acknowledgement_declaration.next_review_year" type="date" format="dd/MM/yyyy" value-format="yyyy-MM-dd" placeholder="" />
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<MyInput placeholder="Please provide details" :textareaValue.sync="client_acknowledgement_declaration.next_review_comment" />
	</div>
</template>

<script>
import SwitchBox from "../../../components/switchBox.vue";
import MyInput from "../components/MyInput.vue";
import MyTitle from "../components/MyTitle.vue";
import ElementRadiogroup from "../../../components/ElementRadiogroup.vue";
import CheckBox from "../components/checkBox.vue";
import { queryList } from '@/api/KYC/index.js'

export default {
	components: { SwitchBox, MyInput, MyTitle, ElementRadiogroup, CheckBox },
	data () {
		var validatorNRIC = (rule, value, callback) => {
			if (this.$validateIC(value)) {
				callback()
			} else {
				callback(new Error('The form of NRIC/Passport is not correct'))
			}
		}
		return {
			nationalityOptions: null,
			relationship: null,
			rules: {
				owner_nric_passportno_fin: [{ validator: validatorNRIC, trigger: 'blur' }],
			},
			client_acknowledgement_declaration: {
				additional_notice: '',
				is_agree: '',
				wish_amendment: '', //is_agree No
				is_investment_linked_plan: '', //2a
				is_experience: '', //2b
				is_aware_ilp_suitability: '', //2c
				is_understand_contacting_income: '',
				is_understand_approval: '',
				// 3)
				is_recommended_plan_nature: '',
				is_my_profile: '',
				is_contractual_rights: '',
				is_recommended_plan_price: '',
				is_commitment_required: '',
				is_free_look_provision: '',
				is_fees_charges: '',
				is_warnings_exclusion_disclaimers: '',
				is_surrender_claim_procedures: '',
				is_recommended_plan_fbrl: '',
				is_plan_provider_details: '',
				is_insurance_fuide: '',
				is_available_policy_document: '',
				// 5)
				is_read_understood: '',
				// 6)
				option_call: '',
				option_email: '',
				option_mail: '',
				option_sms: '',
				// 7)
				is_beneficial_ownership_arrangement: '',
				beneficial_owner_name: '',
				owner_nric_passportno_fin: '',
				owner_birth: '',
				owner_gender: '',
				owner_proposer_relationship: '',
				owner_nationality_type: '',
				// 8)
				is_pep: '',
				pep_name: '',
				pep_title: '',
				pep_related_person_name: '',
				pep_relationship: '',
				// 9)
				is_proposer_payor: '',
				payor_name: '',
				payor_nric_passportno_fin: '',
				payor_occupation: '',
				pay_reason: '',
				payor_proposer_relationship: '',
				payor_others_name: '',
				// 10)
				not_employed_details: '',
				fund_source_salary_commission: '',
				fund_source_policy_proceeds: '',
				fund_source_inheritance: '',
				fund_source_sale_asset: '',
				fund_source_personal_savings: '',
				fund_source_others: '',
				next_review_year: '',
				next_review_comment: '',
				// 不明确字段
				owner_nationality_content: '', // 可能弃用
				explain_people_relationship: '',
				explain_people_name: '',
				is_other_language: '',
				client_signature_id: '',
				en_explain_people_name: '',
				languages_choose: '',
				en_explain_people_relationship: '',
				is_explained_by_myself: '',
				is_explained_by_other: '',
				next_review_month: '',
				signature_date: '',
			},
			paragraph1: "1) I/We understand that the above recommendation(s) is/are based on the facts furnished in My Financial Portfolio, and any "
				+ "incomplete or inaccurate information provided by me/us may affect the suitability of the recommendations made. If I/we choose not to "
				+ "provide the information requested or accept the representative’s recommendations, then it is my/our responsibility to ensure the "
				+ "suitability of the product(s) selected. The representative has explained to me/us in detail the recommendations made and I/we",
			paragraph2: "Agree with the proposed recommendation.",
			paragraph3: "2a) Is one or more of the selected products an investment-linked plan (ILP), Collective Investment Scheme (CIS) or unlisted SIP?",
			paragraph4: "I am/We are aware of my/our risk profile and have selected funds in the ILP/CIS and/or Securities knowing their risk "
				+ "classification. I/We understand that the return(s) of ILP/CIS and/or Securities is/are dependent on the performance of the"
				+ "underlying funds/securities and cash-value is non-guaranteed. My/Our representative has explained to me/us that any"
				+ "illustrations of past performance of funds/securities are not necessarily indicative of future performance of the"
				+ "ILP/CIS/securities. (If ‘yes’, please move to question 2b. If ‘no’, please move to question 3.)",
			paragraph_second_b: "2b) I am/We are assessed to have relevant knowledge and/or experience in ILP/CIS/ unlisted SIP. (Please complete question 2c if you do NOT "
				+ "wish to receive any advice offered by my representative.)",
			paragraph_second_c: "2c) Additional disclosure [to be completed if client(s) do NOT wish to receive any advice offered by my representative and decided to "
				+ "choose(s) to purchase an ILP/CIS/ unlisted SIP that is not recommended by the representative.",
			paragraph_third: "3) My/Our representative has used a copy of My Financial Planner, Policy Illustration, Product Summary and the following documents "
				+ "(if applicable) i) Product Highlights Sheet, ii) Cover Page, iii) Bundled Product Disclosure, iv) Prospectus, v) Term Sheet as a basis for explaining the following: ",
			paragraph_fourth: "4) I/We understand that my/our representative will furnish me with a copy of complete My Financial Planner signed by me/us, and "
				+ "acknowledge that I/we have received a copy of the following documents: Policy Illustration, Product Summary and the following documents (if applicable) i) "
				+ "Product Highlights Sheet, ii) Cover Page, iii) Bundled Product Disclosure, iv) Prospectus, v) Term Sheet; in a mode that is acceptable by me/us.",
			paragraph_fifth: "5) I/We declare that I/we have read and understood:",
			paragraph_fifth_content: "this document, and confirm that the contents are current, true, complete and accurate in all respects that Infinitum may rely on "
				+ "this document; and agreed to the ‘personal data collection statement’ at the start of this document.",
			paragraph_sixth: "6) Marketing Material Option (MMO) Consent",
			paragraph_sixth_content: "I give my consent to Infinitum to collect, use and disclose my personal data, and contact me via the selected channels, for "
				+ "marketing and promotional purposes. I agree that Infinitum will use the contact particulars, including any update that I have given to Infinitum, to contact me.",
			paragraph_sixth_content1: "I may withdraw my consent by contacting Infinitum at 6681 5785 or dpo@infinitumfa.com. Please refer to <a href='https://www.infinitumfa.com/policies.html' target='_blank'>https://www.infinitumfa.com/policies.html</a> for more information.",
			paragraph_seventh: "7) Beneficial Ownership Declaration - This is NOT a nomination of beneficiaries for this policy",
			paragraph_seventh_content: "A Beneficial Owner is defined in the MAS Notice on Prevention of Money Laundering and Countering the Financing of Terrorism as an "
				+ "individual who ultimately owns or controls the customer or the individual on whose behalf a transaction is conducted or business relations are established.",
			paragraph_eighth: "8) Politically Exposed Person (PEP) Declaration",
			paragraph_eighth_content: "A Politically Exposed Person (PEP) is an individual who is, or has been entrusted with prominent public functions whether in "
				+ "Singapore, a foreign country or an international organization. Prominent public function includes the roles held by head of "
				+ "state, a head of government, government ministers, senior civil or public servants, senior judicial or military officials, senior "
				+ "executives of state owned corporations, senior political party officials, members of the legislature, and senior management of international organizations.",
			paragraph_eighth_content1: "Are you, or the Beneficial Owner, a PEP or related to a PEP?",
			checkBoxContent: [
				{ label: 'Nature of the recommended plan(s)', value: 'is_recommended_plan_nature' },
				{ label: 'My profile', value: 'is_my_profile' },
				{ label: 'Contractual rights', value: 'is_contractual_rights' },
				{ label: 'Pricing of the recommended plan(s)', value: 'is_recommended_plan_price' },
				{ label: 'Commitment required', value: 'is_commitment_required' },
				{ label: 'Free-look provision', value: 'is_free_look_provision' },
				{ label: 'Fees and charges', value: 'is_fees_charges' },
				{ label: 'Warnings, exclusion, and disclaimers', value: 'is_warnings_exclusion_disclaimers' },
				{ label: 'Surrender and claim procedures, charges, and restrictions', value: 'is_surrender_claim_procedures' },
				{ label: 'Features, benefits, risks and limitations of the recommended plan(s)', value: 'is_recommended_plan_fbrl' },
				{ label: 'Details of the plan(s) provider', value: 'is_plan_provider_details' },
				{ label: 'A copy of Your Guide to Life/Health Insurance, and/or Your Guide to Investment-Linked Insurance Plans is available and can be requested from my/our representative.', value: 'is_insurance_fuide' },
				{
					label: "A copy of My Financial Planner, Policy Illustration, Product Summary and the following documents (if applicable) i) Product"
						+ "Highlights Sheet, ii) Cover Page, iii) Bundled Product Disclosure, iv) Prospectus, v) Term Sheet will be available as part of my/our policy document.", value: 'is_available_policy_document'
				},
			],
			checkedData_Three: [],
			checkBoxContent_six: [
				{ label: 'Call', value: 'option_call' },
				{ label: 'Email', value: 'option_email' },
				{ label: 'Mail', value: 'option_mail' },
				{ label: 'Text Message/SMS', value: 'option_sms' },
			],
			checkedData_six: [],
			checkBoxContent_ten: [
				{ label: 'Salary or commission', value: 'fund_source_salary_commission' },
				{ label: 'Proceeds from a policy', value: 'fund_source_policy_proceeds' },
				{ label: 'Inheritance', value: 'fund_source_inheritance' },
				{ label: 'Sale of assets', value: 'fund_source_sale_asset' },
				{ label: 'Personal savings', value: 'fund_source_personal_savings' },
				{ label: 'Others (Please provide details below)', value: 'fund_source_others' }
			],
			checkedData_ten: [],
			kycAllData: {},
			timer:null
		};
	},
	computed: {
		kycClient () {
			return this.$store.state.KYC.kycClient
		}
	},
	watch: {
		kycClient (newVal, oldVal) {
			// client切换前调用保存接口-保存数据
			this.setKYC(oldVal)
			if (this.timer) clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
					this.getDataFromAll(newVal)
				})
			}, 200)
		}
	},
	methods: {
		submitForm () {
			this.setKYC(this.kycClient)
			this.$emit('nextBtnRequest')
		},
		setKYC (val) {
			Object.keys(this.kycAllData.kyc.client[val].client_acknowledgement_declaration).map(key => {
				if (key !== 'additional_notice') this.kycAllData.kyc.client[val].client_acknowledgement_declaration[key] = this.client_acknowledgement_declaration[key]
			})
			this.$store.commit('SET_KYC_ALL_DATA', this.kycAllData)
		},
		changeStatus (val, temp) {
			let arr = []
			if (temp === '1') {
				// 问题3 先全设为N 再选中为Y
				this.checkBoxContent.map(key => {
					this.client_acknowledgement_declaration[key.value] = 'N'
				})
			} else if (temp === '2') {
				arr = this.checkBoxContent_six
			} else if (temp === '3') {
				arr = this.checkBoxContent_ten
			}
			arr.map(key => {
				this.client_acknowledgement_declaration[key.value] = ''
			})
			val.map(key => {
				this.client_acknowledgement_declaration[key.value] = 'Y'
			})
		},
		// 全选
		CheckAll () {
			this.$refs.listForm.CheckAll(this.checkBoxContent)
			// 问题3全设置为Y
			this.checkBoxContent.map(key => {
				this.client_acknowledgement_declaration[key.value] = 'Y'
			})
		},
		// 多选设置选中值
		setCheckedData () {
			this.checkBoxContent.map(key => {
				if (this.client_acknowledgement_declaration[key.value] === 'Y') {
					this.checkedData_Three.push(key)
				}
			})
			this.checkBoxContent_six.map(key1 => {
				if (this.client_acknowledgement_declaration[key1.value] === 'Y') {
					this.checkedData_six.push(key1)
				}
			})
			this.checkBoxContent_ten.map(key2 => {
				if (this.client_acknowledgement_declaration[key2.value] === 'Y') {
					this.checkedData_ten.push(key2)
				}
			})
			console.log(this.client_acknowledgement_declaration.owner_birth, 'birth');
		},
		getDataFromAll (val) {
			// 若有值 则赋值
			Object.keys(this.client_acknowledgement_declaration).map(key => {
				this.client_acknowledgement_declaration[key] = this.kycAllData.kyc.client[val].client_acknowledgement_declaration[key]
			})
			this.setCheckedData()
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () {
		this.nationalityOptions = []
		queryList({}).then(res => {
			res.content.countryList.rows.map(item => {
				this.nationalityOptions.push({
					code: item.id,
					name: item.nationality
				})
			})
		})
		this.relationship = global.relationship
		this.kycAllData = this.$store.state.KYC.kycAllData
		this.getDataFromAll(this.kycClient)
	},
	destroyed () {// this.setKYC(this.kycClient)
	},
}
</script>
<style lang='scss' scoped>
p {
	margin-top: 30px;
	font-size: 20px;
	color: #707070;
	line-height: 28px;
	letter-spacing: 0px;
}
.my-form /deep/ .el-input__inner {
	padding-left: 230px !important;
}
.prefixLeft /deep/ .el-input__prefix {
	left: 210px;
}
.prefixLine /deep/ .el-form-item__label {
	line-height: 20px !important;
}
</style>