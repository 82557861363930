<!-- add notes 弹窗 -->
<template>
	<el-dialog title="Note" :visible.sync="dialogVisible" width="30%" @open="open">
		<el-input type="textarea" :rows="4" v-model="note" @input="note = $util.inputReg(note)"></el-input>
		<span slot="footer" class="dialog-footer">
			<el-button type="warning" @click="addNote" size="medium">OK</el-button>
		</span>
	</el-dialog>
</template>

<script>

export default {
	components: {},
    props: {
        noteValue: {
			type: String,
			default: ''
		}
    },
	data () {
		return {
			dialogVisible: false,
			note: ''
		};
	},
	computed: {},
	watch: {},
	methods: {
		open () {
			this.note = this.noteValue
		},
        addNote () {
            this.dialogVisible = false
            this.$emit('setNote', this.note)
        }
    },
    mounted () {}
}
</script>
<style lang='css' scoped>
    .el-dialog >>> .el-dialog__footer {
        text-align: center;
    }
</style>