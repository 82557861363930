<!-- 实现在用户点击确认后保持弹框仍然存在 -->
<template>
  <el-dialog
    title="Alert"
    :visible.sync="dialogVisible"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="25%">
    <span>Your KYC has been locked for further process</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">Return to Dashboard</el-button>
      <el-button type="primary" @click="handleConfirm">Generate PDF & Sign</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: Boolean
  },
  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },
    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style scoped>
/* 样式可以根据需要自行添加 */
.el-dialog__wrapper >>> .el-dialog {
  width: 30%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
</style>
