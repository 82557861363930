/*
 *@Author: 段彩翔
 *@Date: 2022-04-12 17:23:37
 *@Description: kyc流程页面
*/
<template>
	<el-dialog :visible.sync="show" :modal="false" @open="open" :before-close="beforeClose" width="60%" top="50px" :show-close="false" :append-to-body="false" class="dialog">
		<el-timeline>
			<el-timeline-item v-for="(activity, index) in activities" :key="index" :icon="activity.finish ? 'el-icon-success' : ''" @click.native="titmeLine(activity)">
				{{activity.title}}
			</el-timeline-item>
		</el-timeline>
		<el-timeline>
			<el-timeline-item v-for="(activity, index) in activities1" :key="index" :icon="activity.finish ? 'el-icon-success' : ''" @click.native="titmeLine(activity)">
				{{activity.title}}
			</el-timeline-item>
		</el-timeline>
		<el-timeline>
			<el-timeline-item v-for="(activity, index) in activities2" :key="index" :icon="activity.finish ? 'el-icon-success' : ''" @click.native="titmeLine(activity)">
				{{activity.title}}
			</el-timeline-item>
		</el-timeline>
		<span slot="footer" class="dialog-footer">
			<el-button @click="closeDialog" type="danger">Delete</el-button>
			<el-button @click="saveAndQuit">Save & Quit</el-button>
		</span>
	</el-dialog>
</template>

<script>

export default {
	components: {},
	props: {
		showProcessPage: {
			type: Boolean,
			default: false
		},
		currentData: {}
	},
	data () {
		return {
			show: false,
			activities: [
				{
					title: 'Personal Information',
					level: 1,
					index: 1,
					router: 'PersonalInformation',
					key: 'personal_information',
					finish: false
					// icon: 'el-icon-success'
				},
				{
					title: 'Trusted Individual',
					level: 1,
					index: 2,
					router: 'TrustedIndividuals',
					key: 'trusted_individual',
					finish: false
				},
				{
					title: 'Dependants',
					level: 1,
					index: 3,
					router: 'Dependants',
					key: 'dependants_list',
					finish: false
				},
				{
					title: 'Financial Objectives',
					level: 1,
					index: 4,
					router: 'FinancialObjectives',
					key: 'financial_objectives',
					finish: false
				},
				{
					title: 'Assets & Liabilities',
					level: 1,
					index: 5,
					router: 'AssetsLiabilities',
					key: 'assets_liabilities',
					finish: false
				},
				{
					title: 'Cashflow',
					level: 1,
					index: 6,
					router: 'Cashflow',
					key: 'cashflow',
					finish: false
				},
				{
					title: 'Existing Investments & Insurance',
					level: 1,
					index: 7,
					router: 'ExistingInvestmentsInsurance',
					key: 'existing_investments_insurance',
					finish: false
				},
				{ title: 'Budget', level: 1, index: 8, router: 'Budget', key: 'budget', finish: false },
				{
					title: 'Customer Knowledge Assessment',
					level: 1,
					index: 9,
					router: 'CustomerKnowledgeAssessment',
					key: 'customer_knowledge_assessment',
					finish: false
				},
				{
					title: 'Risk Profile Questionnaire',
					level: 1,
					index: 10,
					router: 'RiskProfileQuestionnaire',
					key: 'risk_profile_questionnaire',
					finish: false
				},
				{
					title: 'Accredited Investor Declaration',
					level: 1,
					index: 11,
					router: 'AccreditedInvestorDeclaration',
					key: 'accredited_investor_declaration',
					finish: false
				},
				// TODO needs to ask the backend whether to merge the following two objects with the prototype
				{
					title: 'Need Analysis',
					level: 1,
					index: 12,
					router: 'NeedAnalysis',
					key: 'needs_analysis',
					finish: true,
					disabled: false
				}
			],
			activities1: [
				{
					title: 'Summary of KYC',
					level: 2,
					index: 1,
					router: 'SummaryOfKYC',
					key: 'summary_of_KYC',
					finish: true
				},
				{
					title: 'Product Recommendations',
					level: 2,
					index: 2,
					router: 'ProductRecommendations',
					key: 'basis_recommendations',
					finish: false
				}
			],
			activities2: [
				{
					title: 'Switching / Replacement Policy',
					level: 3,
					index: 1,
					router: 'SwitchingReplacementPolicy',
					key: 'switching_replacement_policy',
					finish: false
				},
				{
					title: 'Client Acknowledgement and Declaration',
					level: 3,
					index: 2,
					router: 'ClientAcknowledgementAndDeclaration',
					key: 'client_acknowledgement_declaration',
					finish: false
				},
				{
					title: 'Lock for E-Sub',
					level: 3,
					index: 3,
					router: 'SignSubmit',
					key: 'sign_submit',
					finish: false
				}
			]
		};
	},
	computed: {},
	watch: {
		showProcessPage () {
			this.show = this.showProcessPage
		}
	},
	methods: {
		titmeLine (val) {
			if (val.disabled) return
			let value = null
			if (val.level === 1) {
				value = val.index - 1
			} else if (val.level === 2) {
				value = val.index + 11
			} else if (val.level === 3) {
				value = val.index + 13
			}
			this.$emit('update:showProcessPage', false)
			// 3.3节点特殊处理
			if (!(val.level === 3 && val.index === 3)) {
				console.log('process changeItem');
				this.$store.commit('SET_KYCPAGEITEM', value)
				this.$emit('changePage')
			} else {
				this.$emit('openLockPage')
			}
		},
		closeDialog () {
			this.$emit('update:showProcessPage', false)
		},
		saveAndQuit () {
			this.kycUpdate(this.$store.state.KYC.kycAllData).then(() => {
				this.$store.commit('SET_PAGEITEM', 2)
				this.$router.push('/')
			})
		},
		beforeClose () {
			this.$emit('update:showProcessPage', false)
		},
		open () {
			// 判断is_ai 为Y时，1.12节点不可点击 为N时，可以点击
			let client_num = this.$store.state.KYC.kycClient
			let is_ai = this.$store.state.KYC.kycAllData.kyc.client[client_num].accredited_investor_declaration.is_ai
			console.log(this.currentData, 'open');
			// 判断各节点是否完成，完成finish为true
			this.activities.map(n => {
				this.currentData.map(data => {
					if (data.node.includes(n.title)) n.finish = data.data.length === 0
					if (n.index === 12) n.disabled = is_ai === 'Y'
				})
			})
			this.activities1.map(n => {
				this.currentData.map(data => {
					if (data.node.includes(n.title)) n.finish = data.data.length === 0
				})
			})
			this.activities2.map(n => {
				this.currentData.map(data => {
					if (data.node.includes(n.title)) n.finish = data.data.length === 0
				})
			})
		}
	},
	//生命周期 - 创建完成（可以访问当前this实例）
	created () { },
	//生命周期 - 挂载完成（可以访问DOM元素）
	mounted () {

	},
}
</script>
<style lang='scss' scoped>
.dialog /deep/ .el-dialog {
	margin-left: 0 !important;
}
.el-timeline {
	width: 32%;
	display: inline-block;
	vertical-align: top;
	/deep/ .el-timeline-item__icon {
		color: #f36a10;
		font-size: 18px;
	}
	/deep/ .el-timeline-item__node--normal {
		left: -2px;
		width: 16px;
		height: 16px;
		cursor: pointer;
	}
	/deep/ .el-timeline-item__wrapper {
		top: 4px;
		cursor: pointer;
	}
}
</style>